export const industryArr = [
    {
        label: "Software Development",
        value: "4",
    },
    {
        label: "IT Services and IT Consulting",
        value: "96",
    },
    {
        label: "Technology, Information and Internet",
        value: "6",
    },
    {
        label: "Strategic Management Services",
        value: "102",
    },
    {
        label: "Financial Services",
        value: "43",
    },
    {
        label: "Banking",
        value: "41",
    },
    {
        label: "Retail",
        value: "27",
    },
    {
        label: "Computer and Network Security",
        value: "118",
    },
    {
        label: "Advertising Services",
        value: "80",
    },
    {
        label: "Hospitals and Health Care",
        value: "14",
    },
    {
        label: "Chiropractors",
        value: "2048",
    },
    {
        label: "Defense and Space Manufacturing",
        value: "1",
    },
    {
        label: "Optometrists",
        value: "2050",
    },
    {
        label: "Computer Hardware Manufacturing",
        value: "3",
    },
    {
        label: "Computer Networking Products",
        value: "5",
    },
    {
        label: "Transportation Equipment Manufacturing",
        value: "1029",
    },
    {
        label: "Physical, Occupational and Speech Therapists",
        value: "2054",
    },
    {
        label: "Semiconductor Manufacturing",
        value: "7",
    },
    {
        label: "Telecommunications",
        value: "8",
    },
    {
        label: "Law Practice",
        value: "9",
    },
    {
        label: "Housing Programs",
        value: "3081",
    },
    {
        label: "Legal Services",
        value: "10",
    },
    {
        label: "Business Consulting and Services",
        value: "11",
    },
    {
        label: "Biotechnology Research",
        value: "12",
    },
    {
        label: "Family Planning Centers",
        value: "2060",
    },
    {
        label: "Medical Practices",
        value: "13",
    },
    {
        label: "Transportation Programs",
        value: "3085",
    },
    {
        label: "Utilities Administration",
        value: "3086",
    },
    {
        label: "Pharmaceutical Manufacturing",
        value: "15",
    },
    {
        label: "Outpatient Care Centers",
        value: "2063",
    },
    {
        label: "Veterinary Services",
        value: "16",
    },
    {
        label: "Medical Equipment Manufacturing",
        value: "17",
    },
    {
        label: "Space Research and Technology",
        value: "3089",
    },
    {
        label: "Motor Vehicle Parts Manufacturing",
        value: "1042",
    },
    {
        label: "Personal Care Product Manufacturing",
        value: "18",
    },
    {
        label: "Retail Apparel and Fashion",
        value: "19",
    },
    {
        label: "Sporting Goods Manufacturing",
        value: "20",
    },
    {
        label: "Tobacco Manufacturing",
        value: "21",
    },
    {
        label: "Medical and Diagnostic Laboratories",
        value: "2069",
    },
    {
        label: "Retail Groceries",
        value: "22",
    },
    {
        label: "Food and Beverage Manufacturing",
        value: "23",
    },
    {
        label: "Oil Extraction",
        value: "3095",
    },
    {
        label: "Computers and Electronics Manufacturing",
        value: "24",
    },
    {
        label: "Natural Gas Extraction",
        value: "3096",
    },
    {
        label: "Manufacturing",
        value: "25",
    },
    {
        label: "Furniture and Home Furnishings Manufacturing",
        value: "26",
    },
    {
        label: "Home Health Care Services",
        value: "2074",
    },
    {
        label: "Embedded Software Products",
        value: "3099",
    },
    {
        label: "Entertainment Providers",
        value: "28",
    },
    {
        label: "Mobile Computing Software Products",
        value: "3100",
    },
    {
        label: "Gambling Facilities and Casinos",
        value: "29",
    },
    {
        label: "Ambulance Services",
        value: "2077",
    },
    {
        label: "Desktop Computing Software Products",
        value: "3101",
    },
    {
        label: "IT System Custom Software Development",
        value: "3102",
    },
    {
        label: "Travel Arrangements",
        value: "30",
    },
    {
        label: "IT System Operations and Maintenance",
        value: "3103",
    },
    {
        label: "Hospitality",
        value: "31",
    },
    {
        label: "IT System Installation and Disposal",
        value: "3104",
    },
    {
        label: "Restaurants",
        value: "32",
    },
    {
        label: "IT System Training and Support",
        value: "3105",
    },
    {
        label: "Hospitals",
        value: "2081",
    },
    {
        label: "Spectator Sports",
        value: "33",
    },
    {
        label: "IT System Data Services",
        value: "3106",
    },
    {
        label: "Food and Beverage Services",
        value: "34",
    },
    {
        label: "IT System Testing and Evaluation",
        value: "3107",
    },
    {
        label: "Movies, Videos, and Sound",
        value: "35",
    },
    {
        label: "Broadcast Media Production and Distribution",
        value: "36",
    },
    {
        label: "Museums, Historical Sites, and Zoos",
        value: "37",
    },
    {
        label: "Artists and Writers",
        value: "38",
    },
    {
        label: "Performing Arts",
        value: "39",
    },
    {
        label: "Recreational Facilities",
        value: "40",
    },
    {
        label: "Insurance",
        value: "42",
    },
    {
        label: "Nursing Homes and Residential Care Facilities",
        value: "2091",
    },
    {
        label: "Real Estate",
        value: "44",
    },
    {
        label: "Investment Banking",
        value: "45",
    },
    {
        label: "Investment Management",
        value: "46",
    },
    {
        label: "Accounting",
        value: "47",
    },
    {
        label: "Construction",
        value: "48",
    },
    {
        label: "Wholesale Building Materials",
        value: "49",
    },
    {
        label: "Architecture and Planning",
        value: "50",
    },
    {
        label: "Civil Engineering",
        value: "51",
    },
    {
        label: "Internet News",
        value: "3124",
    },
    {
        label: "Aviation and Aerospace Component Manufacturing",
        value: "52",
    },
    {
        label: "Blogs",
        value: "3125",
    },
    {
        label: "Motor Vehicle Manufacturing",
        value: "53",
    },
    {
        label: "Interior Design",
        value: "3126",
    },
    {
        label: "Chemical Manufacturing",
        value: "54",
    },
    {
        label: "Social Networking Platforms",
        value: "3127",
    },
    {
        label: "Machinery Manufacturing",
        value: "55",
    },
    {
        label: "Household and Institutional Furniture Manufacturing",
        value: "1080",
    },
    {
        label: "Business Intelligence Platforms",
        value: "3128",
    },
    {
        label: "Mining",
        value: "56",
    },
    {
        label: "Business Content",
        value: "3129",
    },
    {
        label: "Oil and Gas",
        value: "57",
    },
    {
        label: "Data Security Software Products",
        value: "3130",
    },
    {
        label: "Shipbuilding",
        value: "58",
    },
    {
        label: "Utilities",
        value: "59",
    },
    {
        label: "Mobile Gaming Apps",
        value: "3131",
    },
    {
        label: "Textile Manufacturing",
        value: "60",
    },
    {
        label: "Internet Publishing",
        value: "3132",
    },
    {
        label: "Paper and Forest Product Manufacturing",
        value: "61",
    },
    {
        label: "Media and Telecommunications",
        value: "3133",
    },
    {
        label: "Railroad Equipment Manufacturing",
        value: "62",
    },
    {
        label: "Blockchain Services",
        value: "3134",
    },
    {
        label: "Farming",
        value: "63",
    },
    {
        label: "Services for the Elderly and Disabled",
        value: "2112",
    },
    {
        label: "Ranching",
        value: "64",
    },
    {
        label: "Dairy Product Manufacturing",
        value: "65",
    },
    {
        label: "Office Furniture and Fixtures Manufacturing",
        value: "1090",
    },
    {
        label: "Fisheries",
        value: "66",
    },
    {
        label: "Community Services",
        value: "2115",
    },
    {
        label: "Primary and Secondary Education",
        value: "67",
    },
    {
        label: "Higher Education",
        value: "68",
    },
    {
        label: "Education Administration Programs",
        value: "69",
    },
    {
        label: "Research Services",
        value: "70",
    },
    {
        label: "Mattress and Blinds Manufacturing",
        value: "1095",
    },
    {
        label: "Armed Forces",
        value: "71",
    },
    {
        label: "Legislative Offices",
        value: "72",
    },
    {
        label: "Administration of Justice",
        value: "73",
    },
    {
        label: "International Affairs",
        value: "74",
    },
    {
        label: "Emergency and Relief Services",
        value: "2122",
    },
    {
        label: "Government Administration",
        value: "75",
    },
    {
        label: "Executive Offices",
        value: "76",
    },
    {
        label: "Law Enforcement",
        value: "77",
    },
    {
        label: "Vocational Rehabilitation Services",
        value: "2125",
    },
    {
        label: "Public Safety",
        value: "78",
    },
    {
        label: "Public Policy Offices",
        value: "79",
    },
    {
        label: "Child Day Care Services",
        value: "2128",
    },
    {
        label: "Newspaper Publishing",
        value: "81",
    },
    {
        label: "Performing Arts and Spectator Sports",
        value: "2130",
    },
    {
        label: "Book and Periodical Publishing",
        value: "82",
    },
    {
        label: "Printing Services",
        value: "83",
    },
    {
        label: "Information Services",
        value: "84",
    },
    {
        label: "Libraries",
        value: "85",
    },
    {
        label: "Theater Companies",
        value: "2133",
    },
    {
        label: "Environmental Services",
        value: "86",
    },
    {
        label: "Freight and Package Transportation",
        value: "87",
    },
    {
        label: "Dance Companies",
        value: "2135",
    },
    {
        label: "Individual and Family Services",
        value: "88",
    },
    {
        label: "Religious Institutions",
        value: "89",
    },
    {
        label: "Civic and Social Organizations",
        value: "90",
    },
    {
        label: "Consumer Services",
        value: "91",
    },
    {
        label: "Circuses and Magic Shows",
        value: "2139",
    },
    {
        label: "Truck Transportation",
        value: "92",
    },
    {
        label: "Warehousing and Storage",
        value: "93",
    },
    {
        label: "Sports Teams and Clubs",
        value: "2142",
    },
    {
        label: "Airlines and Aviation",
        value: "94",
    },
    {
        label: "Maritime Transportation",
        value: "95",
    },
    {
        label: "Racetracks",
        value: "2143",
    },
    {
        label: "Market Research",
        value: "97",
    },
    {
        label: "Public Relations and Communications Services",
        value: "98",
    },
    {
        label: "Design Services",
        value: "99",
    },
    {
        label: "Non-profit Organizations",
        value: "100",
    },
    {
        label: "Fundraising",
        value: "101",
    },
    {
        label: "Writing and Editing",
        value: "103",
    },
    {
        label: "Staffing and Recruiting",
        value: "104",
    },
    {
        label: "Wholesale Motor Vehicles and Parts",
        value: "1128",
    },
    {
        label: "Professional Training and Coaching",
        value: "105",
    },
    {
        label: "Venture Capital and Private Equity Principals",
        value: "106",
    },
    {
        label: "Political Organizations",
        value: "107",
    },
    {
        label: "Translation and Localization",
        value: "108",
    },
    {
        label: "Computer Games",
        value: "109",
    },
    {
        label: "Events Services",
        value: "110",
    },
    {
        label: "Retail Art Supplies",
        value: "111",
    },
    {
        label: "Museums",
        value: "2159",
    },
    {
        label: "Appliances, Electrical, and Electronics Manufacturing",
        value: "112",
    },
    {
        label: "Online Audio and Video Media",
        value: "113",
    },
    {
        label: "Wholesale Furniture and Home Furnishings",
        value: "1137",
    },
    {
        label: "Historical Sites",
        value: "2161",
    },
    {
        label: "Nanotechnology Research",
        value: "114",
    },
    {
        label: "Retail Art Dealers",
        value: "3186",
    },
    {
        label: "Musicians",
        value: "115",
    },
    {
        label: "Zoos and Botanical Gardens",
        value: "2163",
    },
    {
        label: "Transportation, Logistics, Supply Chain and Storage",
        value: "116",
    },
    {
        label: "Plastics Manufacturing",
        value: "117",
    },
    {
        label: "Wireless Services",
        value: "119",
    },
    {
        label: "Amusement Parks and Arcades",
        value: "2167",
    },
    {
        label: "Alternative Dispute Resolution",
        value: "120",
    },
    {
        label: "Security and Investigations",
        value: "121",
    },
    {
        label: "Facilities Services",
        value: "122",
    },
    {
        label: "Outsourcing and Offshoring Consulting",
        value: "123",
    },
    {
        label: "Wellness and Fitness Services",
        value: "124",
    },
    {
        label: "Alternative Medicine",
        value: "125",
    },
    {
        label: "Media Production",
        value: "126",
    },
    {
        label: "Animation and Post-production",
        value: "127",
    },
    {
        label: "Leasing Non-residential Real Estate",
        value: "128",
    },
    {
        label: "Capital Markets",
        value: "129",
    },
    {
        label: "Wholesale Photography Equipment and Supplies",
        value: "1153",
    },
    {
        label: "Think Tanks",
        value: "130",
    },
    {
        label: "Philanthropic Fundraising Services",
        value: "131",
    },
    {
        label: "Golf Courses and Country Clubs",
        value: "2179",
    },
    {
        label: "E-Learning Providers",
        value: "132",
    },
    {
        label: "Wholesale",
        value: "133",
    },
    {
        label: "Wholesale Computer Equipment",
        value: "1157",
    },
    {
        label: "Skiing Facilities",
        value: "2181",
    },
    {
        label: "Wholesale Import and Export",
        value: "134",
    },
    {
        label: "Industrial Machinery Manufacturing",
        value: "135",
    },
    {
        label: "Photography",
        value: "136",
    },
    {
        label: "Human Resources Services",
        value: "137",
    },
    {
        label: "Retail Office Equipment",
        value: "138",
    },
    {
        label: "Mental Health Care",
        value: "139",
    },
    {
        label: "Graphic Design",
        value: "140",
    },
    {
        label: "International Trade and Development",
        value: "141",
    },
    {
        label: "Beverage Manufacturing",
        value: "142",
    },
    {
        label: "Accommodation and Food Services",
        value: "2190",
    },
    {
        label: "Wholesale Metals and Minerals",
        value: "1166",
    },
    {
        label: "Retail Luxury Goods and Jewelry",
        value: "143",
    },
    {
        label: "Renewable Energy Semiconductor Manufacturing",
        value: "144",
    },
    {
        label: "Glass, Ceramics and Concrete Manufacturing",
        value: "145",
    },
    {
        label: "Packaging and Containers Manufacturing",
        value: "146",
    },
    {
        label: "Hotels and Motels",
        value: "2194",
    },
    {
        label: "Automation Machinery Manufacturing",
        value: "147",
    },
    {
        label: "Wholesale Appliances, Electrical, and Electronics",
        value: "1171",
    },
    {
        label: "Government Relations Services",
        value: "148",
    },
    {
        label: "Bed-and-Breakfasts, Hostels, Homestays",
        value: "2197",
    },
    {
        label: "Horticulture",
        value: "150",
    },
    {
        label: "Wholesale Hardware, Plumbing, Heating Equipment",
        value: "1178",
    },
    {
        label: "Wholesale Machinery",
        value: "1187",
    },
    {
        label: "Caterers",
        value: "2212",
    },
    {
        label: "Mobile Food Services",
        value: "2214",
    },
    {
        label: "Renewable Energy Power Generation",
        value: "3240",
    },
    {
        label: "Bars, Taverns, and Nightclubs",
        value: "2217",
    },
    {
        label: "Renewable Energy Equipment Manufacturing",
        value: "3241",
    },
    {
        label: "Engineering Services",
        value: "3242",
    },
    {
        label: "Services for Renewable Energy",
        value: "3243",
    },
    {
        label: "Digital Accessibility Services",
        value: "3244",
    },
    {
        label: "Accessible Hardware Manufacturing",
        value: "3245",
    },
    {
        label: "Accessible Architecture and Design",
        value: "3246",
    },
    {
        label: "Robot Manufacturing",
        value: "3247",
    },
    {
        label: "Robotics Engineering",
        value: "3248",
    },
    {
        label: "Repair and Maintenance",
        value: "2225",
    },
    {
        label: "Surveying and Mapping Services",
        value: "3249",
    },
    {
        label: "Vehicle Repair and Maintenance",
        value: "2226",
    },
    {
        label: "Retail Pharmacies",
        value: "3250",
    },
    {
        label: "Climate Technology Product Manufacturing",
        value: "3251",
    },
    {
        label: "Climate Data and Analytics",
        value: "3252",
    },
    {
        label: "Alternative Fuel Vehicle Manufacturing",
        value: "3253",
    },
    {
        label: "Wholesale Recyclable Materials",
        value: "1206",
    },
    {
        label: "Smart Meter Manufacturing",
        value: "3254",
    },
    {
        label: "Fuel Cell Manufacturing",
        value: "3255",
    },
    {
        label: "Wholesale Luxury Goods and Jewelry",
        value: "1208",
    },
    {
        label: "Regenerative Design",
        value: "3256",
    },
    {
        label: "Wholesale Paper Products",
        value: "1212",
    },
    {
        label: "Electronic and Precision Equipment Maintenance",
        value: "2240",
    },
    {
        label: "Wholesale Drugs and Sundries",
        value: "1221",
    },
    {
        label: "Wholesale Apparel and Sewing Supplies",
        value: "1222",
    },
    {
        label: "Commercial and Industrial Machinery Maintenance",
        value: "2247",
    },
    {
        label: "Farming, Ranching, Forestry",
        value: "201",
    },
    {
        label: "Reupholstery and Furniture Repair",
        value: "2253",
    },
    {
        label: "Wholesale Footwear",
        value: "1230",
    },
    {
        label: "Wholesale Food and Beverage",
        value: "1231",
    },
    {
        label: "Footwear and Leather Goods Repair",
        value: "2255",
    },
    {
        label: "Personal and Laundry Services",
        value: "2258",
    },
    {
        label: "Personal Care Services",
        value: "2259",
    },
    {
        label: "Laundry and Drycleaning Services",
        value: "2272",
    },
    {
        label: "Wholesale Raw Farm Products",
        value: "1250",
    },
    {
        label: "Wholesale Chemical and Allied Products",
        value: "1257",
    },
    {
        label: "Pet Services",
        value: "2282",
    },
    {
        label: "Wholesale Petroleum and Petroleum Products",
        value: "1262",
    },
    {
        label: "Wholesale Alcoholic Beverages",
        value: "1267",
    },
    {
        label: "Ranching and Fisheries",
        value: "256",
    },
    {
        label: "Internet Marketplace Platforms",
        value: "1285",
    },
    {
        label: "Retail Motor Vehicles",
        value: "1292",
    },
    {
        label: "Household Services",
        value: "2318",
    },
    {
        label: "Retail Furniture and Home Furnishings",
        value: "1309",
    },
    {
        label: "Retail Appliances, Electrical, and Electronic Equipment",
        value: "1319",
    },
    {
        label: "Forestry and Logging",
        value: "298",
    },
    {
        label: "Retail Building Materials and Garden Equipment",
        value: "1324",
    },
    {
        label: "Health and Human Services",
        value: "2353",
    },
    {
        label: "Public Health",
        value: "2358",
    },
    {
        label: "Public Assistance Programs",
        value: "2360",
    },
    {
        label: "Food and Beverage Retail",
        value: "1339",
    },
    {
        label: "Air, Water, and Waste Program Management",
        value: "2366",
    },
    {
        label: "Conservation Programs",
        value: "2368",
    },
    {
        label: "Housing and Community Development",
        value: "2369",
    },
    {
        label: "Community Development and Urban Planning",
        value: "2374",
    },
    {
        label: "Economic Programs",
        value: "2375",
    },
    {
        label: "Oil, Gas, and Mining",
        value: "332",
    },
    {
        label: "Retail Health and Personal Care Products",
        value: "1359",
    },
    {
        label: "Coal Mining",
        value: "341",
    },
    {
        label: "Military and International Affairs",
        value: "2391",
    },
    {
        label: "Metal Ore Mining",
        value: "345",
    },
    {
        label: "Retail Gasoline",
        value: "1370",
    },
    {
        label: "Operations Consulting",
        value: "2401",
    },
    {
        label: "Nonmetallic Mineral Mining",
        value: "356",
    },
    {
        label: "Electric Power Transmission, Control, and Distribution",
        value: "382",
    },
    {
        label: "Retail Musical Instruments",
        value: "1407",
    },
    {
        label: "Electric Power Generation",
        value: "383",
    },
    {
        label: "Hydroelectric Power Generation",
        value: "384",
    },
    {
        label: "Retail Books and Printed News",
        value: "1409",
    },
    {
        label: "Fossil Fuel Electric Power Generation",
        value: "385",
    },
    {
        label: "Nuclear Electric Power Generation",
        value: "386",
    },
    {
        label: "Solar Electric Power Generation",
        value: "387",
    },
    {
        label: "Environmental Quality Programs",
        value: "388",
    },
    {
        label: "Geothermal Electric Power Generation",
        value: "389",
    },
    {
        label: "Biomass Electric Power Generation",
        value: "390",
    },
    {
        label: "Natural Gas Distribution",
        value: "397",
    },
    {
        label: "Water, Waste, Steam, and Air Conditioning Services",
        value: "398",
    },
    {
        label: "Retail Florists",
        value: "1423",
    },
    {
        label: "Retail Office Supplies and Gifts",
        value: "1424",
    },
    {
        label: "Water Supply and Irrigation Systems",
        value: "400",
    },
    {
        label: "Steam and Air-Conditioning Supply",
        value: "404",
    },
    {
        label: "Building Construction",
        value: "406",
    },
    {
        label: "Retail Recyclable Materials & Used Merchandise",
        value: "1431",
    },
    {
        label: "Residential Building Construction",
        value: "408",
    },
    {
        label: "Data Infrastructure and Analytics",
        value: "2458",
    },
    {
        label: "Nonresidential Building Construction",
        value: "413",
    },
    {
        label: "Utility System Construction",
        value: "419",
    },
    {
        label: "Electrical Equipment Manufacturing",
        value: "2468",
    },
    {
        label: "Online and Mail Order Retail",
        value: "1445",
    },
    {
        label: "Subdivision of Land",
        value: "428",
    },
    {
        label: "Highway, Street, and Bridge Construction",
        value: "431",
    },
    {
        label: "Specialty Trade Contractors",
        value: "435",
    },
    {
        label: "Building Structure and Exterior Contractors",
        value: "436",
    },
    {
        label: "Wind Electric Power Generation",
        value: "2489",
    },
    {
        label: "Wineries",
        value: "2500",
    },
    {
        label: "Building Equipment Contractors",
        value: "453",
    },
    {
        label: "Rail Transportation",
        value: "1481",
    },
    {
        label: "Building Finishing Contractors",
        value: "460",
    },
    {
        label: "Ground Passenger Transportation",
        value: "1495",
    },
    {
        label: "Urban Transit Services",
        value: "1497",
    },
    {
        label: "Interurban and Rural Bus Services",
        value: "1504",
    },
    {
        label: "Taxi and Limousine Services",
        value: "1505",
    },
    {
        label: "Animal Feed Manufacturing",
        value: "481",
    },
    {
        label: "School and Employee Bus Services",
        value: "1512",
    },
    {
        label: "Shuttles and Special Needs Transportation Services",
        value: "1517",
    },
    {
        label: "Sugar and Confectionery Product Manufacturing",
        value: "495",
    },
    {
        label: "Pipeline Transportation",
        value: "1520",
    },
    {
        label: "Fruit and Vegetable Preserves Manufacturing",
        value: "504",
    },
    {
        label: "Sightseeing Transportation",
        value: "1532",
    },
    {
        label: "Meat Products Manufacturing",
        value: "521",
    },
    {
        label: "Seafood Product Manufacturing",
        value: "528",
    },
    {
        label: "Baked Goods Manufacturing",
        value: "529",
    },
    {
        label: "Postal Services",
        value: "1573",
    },
    {
        label: "Breweries",
        value: "562",
    },
    {
        label: "Distilleries",
        value: "564",
    },
    {
        label: "Technology, Information and Media",
        value: "1594",
    },
    {
        label: "Periodical Publishing",
        value: "1600",
    },
    {
        label: "Book Publishing",
        value: "1602",
    },
    {
        label: "Movies and Sound Recording",
        value: "1611",
    },
    {
        label: "Apparel Manufacturing",
        value: "598",
    },
    {
        label: "Sound Recording",
        value: "1623",
    },
    {
        label: "Sheet Music Publishing",
        value: "1625",
    },
    {
        label: "Radio and Television Broadcasting",
        value: "1633",
    },
    {
        label: "Fashion Accessories Manufacturing",
        value: "615",
    },
    {
        label: "Leather Product Manufacturing",
        value: "616",
    },
    {
        label: "Cable and Satellite Programming",
        value: "1641",
    },
    {
        label: "Telecommunications Carriers",
        value: "1644",
    },
    {
        label: "Footwear Manufacturing",
        value: "622",
    },
    {
        label: "Satellite Telecommunications",
        value: "1649",
    },
    {
        label: "Women's Handbag Manufacturing",
        value: "625",
    },
    {
        label: "Credit Intermediation",
        value: "1673",
    },
    {
        label: "Savings Institutions",
        value: "1678",
    },
    {
        label: "Loan Brokers",
        value: "1696",
    },
    {
        label: "Oil and Coal Product Manufacturing",
        value: "679",
    },
    {
        label: "Securities and Commodity Exchanges",
        value: "1713",
    },
    {
        label: "Chemical Raw Materials Manufacturing",
        value: "690",
    },
    {
        label: "Investment Advice",
        value: "1720",
    },
    {
        label: "Insurance Carriers",
        value: "1725",
    },
    {
        label: "Artificial Rubber and Synthetic Fiber Manufacturing",
        value: "703",
    },
    {
        label: "Agricultural Chemical Manufacturing",
        value: "709",
    },
    {
        label: "Insurance Agencies and Brokerages",
        value: "1737",
    },
    {
        label: "Claims Adjusting, Actuarial Services",
        value: "1738",
    },
    {
        label: "Funds and Trusts",
        value: "1742",
    },
    {
        label: "Insurance and Employee Benefit Funds",
        value: "1743",
    },
    {
        label: "Pension Funds",
        value: "1745",
    },
    {
        label: "Paint, Coating, and Adhesive Manufacturing",
        value: "722",
    },
    {
        label: "Trusts and Estates",
        value: "1750",
    },
    {
        label: "Soap and Cleaning Product Manufacturing",
        value: "727",
    },
    {
        label: "Real Estate and Equipment Rental Services",
        value: "1757",
    },
    {
        label: "Leasing Residential Real Estate",
        value: "1759",
    },
    {
        label: "Plastics and Rubber Product Manufacturing",
        value: "743",
    },
    {
        label: "Real Estate Agents and Brokers",
        value: "1770",
    },
    {
        label: "Equipment Rental Services",
        value: "1779",
    },
    {
        label: "Consumer Goods Rental",
        value: "1786",
    },
    {
        label: "Rubber Products Manufacturing",
        value: "763",
    },
    {
        label: "Clay and Refractory Products Manufacturing",
        value: "773",
    },
    {
        label: "Commercial and Industrial Equipment Rental",
        value: "1798",
    },
    {
        label: "Glass Product Manufacturing",
        value: "779",
    },
    {
        label: "Wood Product Manufacturing",
        value: "784",
    },
    {
        label: "Professional Services",
        value: "1810",
    },
    {
        label: "Lime and Gypsum Products Manufacturing",
        value: "794",
    },
    {
        label: "Abrasives and Nonmetallic Minerals Manufacturing",
        value: "799",
    },
    {
        label: "Primary Metal Manufacturing",
        value: "807",
    },
    {
        label: "IT System Design Services",
        value: "1855",
    },
    {
        label: "Marketing Services",
        value: "1862",
    },
    {
        label: "Fabricated Metal Products",
        value: "840",
    },
    {
        label: "Cutlery and Handtool Manufacturing",
        value: "849",
    },
    {
        label: "Architectural and Structural Metal Manufacturing",
        value: "852",
    },
    {
        label: "Boilers, Tanks, and Shipping Container Manufacturing",
        value: "861",
    },
    {
        label: "Construction Hardware Manufacturing",
        value: "871",
    },
    {
        label: "Spring and Wire Product Manufacturing",
        value: "873",
    },
    {
        label: "Turned Products and Fastener Manufacturing",
        value: "876",
    },
    {
        label: "Holding Companies",
        value: "1905",
    },
    {
        label: "Metal Treatments",
        value: "883",
    },
    {
        label: "Industry Associations",
        value: "1909",
    },
    {
        label: "Landscaping Services",
        value: "2934",
    },
    {
        label: "Professional Organizations",
        value: "1911",
    },
    {
        label: "Metal Valve, Ball, and Roller Manufacturing",
        value: "887",
    },
    {
        label: "Administrative and Support Services",
        value: "1912",
    },
    {
        label: "Office Administration",
        value: "1916",
    },
    {
        label: "Executive Search Services",
        value: "1923",
    },
    {
        label: "Temporary Help Services",
        value: "1925",
    },
    {
        label: "Agriculture, Construction, Mining Machinery Manufacturing",
        value: "901",
    },
    {
        label: "Telephone Call Centers",
        value: "1931",
    },
    {
        label: "Collection Agencies",
        value: "1938",
    },
    {
        label: "Commercial and Service Industry Machinery Manufacturing",
        value: "918",
    },
    {
        label: "HVAC and Refrigeration Equipment Manufacturing",
        value: "923",
    },
    {
        label: "Metalworking Machinery Manufacturing",
        value: "928",
    },
    {
        label: "Security Guards and Patrol Services",
        value: "1956",
    },
    {
        label: "Security Systems Services",
        value: "1958",
    },
    {
        label: "Engines and Power Transmission Equipment Manufacturing",
        value: "935",
    },
    {
        label: "Janitorial Services",
        value: "1965",
    },
    {
        label: "Waste Collection",
        value: "1981",
    },
    {
        label: "Waste Treatment and Disposal",
        value: "1986",
    },
    {
        label: "Communications Equipment Manufacturing",
        value: "964",
    },
    {
        label: "Audio and Video Equipment Manufacturing",
        value: "973",
    },
    {
        label: "Education",
        value: "1999",
    },
    {
        label: "Measuring and Control Instrument Manufacturing",
        value: "983",
    },
    {
        label: "Secretarial Schools",
        value: "2012",
    },
    {
        label: "Technical and Vocational Training",
        value: "2018",
    },
    {
        label: "Magnetic and Optical Media Manufacturing",
        value: "994",
    },
    {
        label: "Cosmetology and Barber Schools",
        value: "2019",
    },
    {
        label: "Flight Training",
        value: "2020",
    },
    {
        label: "Electric Lighting Equipment Manufacturing",
        value: "998",
    },
    {
        label: "Fine Arts Schools",
        value: "2025",
    },
    {
        label: "Sports and Recreation Instruction",
        value: "2027",
    },
    {
        label: "Household Appliance Manufacturing",
        value: "1005",
    },
    {
        label: "Language Schools",
        value: "2029",
    },
    {
        label: "Physicians",
        value: "2040",
    },
    {
        label: "Courts of Law",
        value: "3065",
    },
    {
        label: "Correctional Institutions",
        value: "3068",
    },
    {
        label: "Dentists",
        value: "2045",
    },
    {
        label: "Fire Protection",
        value: "3070",
    },
];

export const jobFunctionArr = [
    {
        label: "Business Development",
        value: "4",
    },
    {
        label: "Engineering",
        value: "8",
    },
    {
        label: "Accounting",
        value: "1",
    },
    {
        label: "Administrative",
        value: "2",
    },
    {
        label: "Arts and Design",
        value: "3",
    },
    {
        label: "Community and Social Services",
        value: "5",
    },
    {
        label: "Consulting",
        value: "6",
    },
    {
        label: "Education",
        value: "7",
    },
    {
        label: "Entrepreneurship",
        value: "9",
    },
    {
        label: "Finance",
        value: "10",
    },
    {
        label: "Healthcare Services",
        value: "11",
    },
    {
        label: "Human Resources",
        value: "12",
    },
    {
        label: "Information Technology",
        value: "13",
    },
    {
        label: "Legal",
        value: "14",
    },
    {
        label: "Marketing",
        value: "15",
    },
    {
        label: "Media and Communication",
        value: "16",
    },
    {
        label: "Military and Protective Services",
        value: "17",
    },
    {
        label: "Operations",
        value: "18",
    },
    {
        label: "Product Management",
        value: "19",
    },
    {
        label: "Program and Project Management",
        value: "20",
    },
    {
        label: "Purchasing",
        value: "21",
    },
    {
        label: "Quality Assurance",
        value: "22",
    },
    {
        label: "Real Estate",
        value: "23",
    },
    {
        label: "Research",
        value: "24",
    },
    {
        label: "Sales",
        value: "25",
    },
    {
        label: "Customer Success and Support",
        value: "26",
    },
];

export const industryOptions = {
    "Software Development": "4",
    "IT Services and IT Consulting": "96",
    "Technology, Information and Internet": "6",
    "Strategic Management Services": "102",
    "Financial Services": "43",
    Banking: "41",
    Retail: "27",
    "Computer and Network Security": "118",
    "Advertising Services": "80",
    "Hospitals and Health Care": "14",
    Chiropractors: "2048",
    "Defense and Space Manufacturing": "1",
    Optometrists: "2050",
    "Computer Hardware Manufacturing": "3",
    "Computer Networking Products": "5",
    "Transportation Equipment Manufacturing": "1029",
    "Physical, Occupational and Speech Therapists": "2054",
    "Semiconductor Manufacturing": "7",
    Telecommunications: "8",
    "Law Practice": "9",
    "Housing Programs": "3081",
    "Legal Services": "10",
    "Business Consulting and Services": "11",
    "Biotechnology Research": "12",
    "Family Planning Centers": "2060",
    "Medical Practices": "13",
    "Transportation Programs": "3085",
    "Utilities Administration": "3086",
    "Pharmaceutical Manufacturing": "15",
    "Outpatient Care Centers": "2063",
    "Veterinary Services": "16",
    "Medical Equipment Manufacturing": "17",
    "Space Research and Technology": "3089",
    "Motor Vehicle Parts Manufacturing": "1042",
    "Personal Care Product Manufacturing": "18",
    "Retail Apparel and Fashion": "19",
    "Sporting Goods Manufacturing": "20",
    "Tobacco Manufacturing": "21",
    "Medical and Diagnostic Laboratories": "2069",
    "Retail Groceries": "22",
    "Food and Beverage Manufacturing": "23",
    "Oil Extraction": "3095",
    "Computers and Electronics Manufacturing": "24",
    "Natural Gas Extraction": "3096",
    Manufacturing: "25",
    "Furniture and Home Furnishings Manufacturing": "26",
    "Home Health Care Services": "2074",
    "Embedded Software Products": "3099",
    "Entertainment Providers": "28",
    "Mobile Computing Software Products": "3100",
    "Gambling Facilities and Casinos": "29",
    "Ambulance Services": "2077",
    "Desktop Computing Software Products": "3101",
    "IT System Custom Software Development": "3102",
    "Travel Arrangements": "30",
    "IT System Operations and Maintenance": "3103",
    Hospitality: "31",
    "IT System Installation and Disposal": "3104",
    Restaurants: "32",
    "IT System Training and Support": "3105",
    Hospitals: "2081",
    "Spectator Sports": "33",
    "IT System Data Services": "3106",
    "Food and Beverage Services": "34",
    "IT System Testing and Evaluation": "3107",
    "Movies, Videos, and Sound": "35",
    "Broadcast Media Production and Distribution": "36",
    "Museums, Historical Sites, and Zoos": "37",
    "Artists and Writers": "38",
    "Performing Arts": "39",
    "Recreational Facilities": "40",
    Insurance: "42",
    "Nursing Homes and Residential Care Facilities": "2091",
    "Real Estate": "44",
    "Investment Banking": "45",
    "Investment Management": "46",
    Accounting: "47",
    Construction: "48",
    "Wholesale Building Materials": "49",
    "Architecture and Planning": "50",
    "Civil Engineering": "51",
    "Internet News": "3124",
    "Aviation and Aerospace Component Manufacturing": "52",
    Blogs: "3125",
    "Motor Vehicle Manufacturing": "53",
    "Interior Design": "3126",
    "Chemical Manufacturing": "54",
    "Social Networking Platforms": "3127",
    "Machinery Manufacturing": "55",
    "Household and Institutional Furniture Manufacturing": "1080",
    "Business Intelligence Platforms": "3128",
    Mining: "56",
    "Business Content": "3129",
    "Oil and Gas": "57",
    "Data Security Software Products": "3130",
    Shipbuilding: "58",
    Utilities: "59",
    "Mobile Gaming Apps": "3131",
    "Textile Manufacturing": "60",
    "Internet Publishing": "3132",
    "Paper and Forest Product Manufacturing": "61",
    "Media and Telecommunications": "3133",
    "Railroad Equipment Manufacturing": "62",
    "Blockchain Services": "3134",
    Farming: "63",
    "Services for the Elderly and Disabled": "2112",
    Ranching: "64",
    "Dairy Product Manufacturing": "65",
    "Office Furniture and Fixtures Manufacturing": "1090",
    Fisheries: "66",
    "Community Services": "2115",
    "Primary and Secondary Education": "67",
    "Higher Education": "68",
    "Education Administration Programs": "69",
    "Research Services": "70",
    "Mattress and Blinds Manufacturing": "1095",
    "Armed Forces": "71",
    "Legislative Offices": "72",
    "Administration of Justice": "73",
    "International Affairs": "74",
    "Emergency and Relief Services": "2122",
    "Government Administration": "75",
    "Executive Offices": "76",
    "Law Enforcement": "77",
    "Vocational Rehabilitation Services": "2125",
    "Public Safety": "78",
    "Public Policy Offices": "79",
    "Child Day Care Services": "2128",
    "Newspaper Publishing": "81",
    "Performing Arts and Spectator Sports": "2130",
    "Book and Periodical Publishing": "82",
    "Printing Services": "83",
    "Information Services": "84",
    Libraries: "85",
    "Theater Companies": "2133",
    "Environmental Services": "86",
    "Freight and Package Transportation": "87",
    "Dance Companies": "2135",
    "Individual and Family Services": "88",
    "Religious Institutions": "89",
    "Civic and Social Organizations": "90",
    "Consumer Services": "91",
    "Circuses and Magic Shows": "2139",
    "Truck Transportation": "92",
    "Warehousing and Storage": "93",
    "Sports Teams and Clubs": "2142",
    "Airlines and Aviation": "94",
    "Maritime Transportation": "95",
    Racetracks: "2143",
    "Market Research": "97",
    "Public Relations and Communications Services": "98",
    "Design Services": "99",
    "Non-profit Organizations": "100",
    Fundraising: "101",
    "Writing and Editing": "103",
    "Staffing and Recruiting": "104",
    "Wholesale Motor Vehicles and Parts": "1128",
    "Professional Training and Coaching": "105",
    "Venture Capital and Private Equity Principals": "106",
    "Political Organizations": "107",
    "Translation and Localization": "108",
    "Computer Games": "109",
    "Events Services": "110",
    "Retail Art Supplies": "111",
    Museums: "2159",
    "Appliances, Electrical, and Electronics Manufacturing": "112",
    "Online Audio and Video Media": "113",
    "Wholesale Furniture and Home Furnishings": "1137",
    "Historical Sites": "2161",
    "Nanotechnology Research": "114",
    "Retail Art Dealers": "3186",
    Musicians: "115",
    "Zoos and Botanical Gardens": "2163",
    "Transportation, Logistics, Supply Chain and Storage": "116",
    "Plastics Manufacturing": "117",
    "Wireless Services": "119",
    "Amusement Parks and Arcades": "2167",
    "Alternative Dispute Resolution": "120",
    "Security and Investigations": "121",
    "Facilities Services": "122",
    "Outsourcing and Offshoring Consulting": "123",
    "Wellness and Fitness Services": "124",
    "Alternative Medicine": "125",
    "Media Production": "126",
    "Animation and Post-production": "127",
    "Leasing Non-residential Real Estate": "128",
    "Capital Markets": "129",
    "Wholesale Photography Equipment and Supplies": "1153",
    "Think Tanks": "130",
    "Philanthropic Fundraising Services": "131",
    "Golf Courses and Country Clubs": "2179",
    "E-Learning Providers": "132",
    Wholesale: "133",
    "Wholesale Computer Equipment": "1157",
    "Skiing Facilities": "2181",
    "Wholesale Import and Export": "134",
    "Industrial Machinery Manufacturing": "135",
    Photography: "136",
    "Human Resources Services": "137",
    "Retail Office Equipment": "138",
    "Mental Health Care": "139",
    "Graphic Design": "140",
    "International Trade and Development": "141",
    "Beverage Manufacturing": "142",
    "Accommodation and Food Services": "2190",
    "Wholesale Metals and Minerals": "1166",
    "Retail Luxury Goods and Jewelry": "143",
    "Renewable Energy Semiconductor Manufacturing": "144",
    "Glass, Ceramics and Concrete Manufacturing": "145",
    "Packaging and Containers Manufacturing": "146",
    "Hotels and Motels": "2194",
    "Automation Machinery Manufacturing": "147",
    "Wholesale Appliances, Electrical, and Electronics": "1171",
    "Government Relations Services": "148",
    "Bed-and-Breakfasts, Hostels, Homestays": "2197",
    Horticulture: "150",
    "Wholesale Hardware, Plumbing, Heating Equipment": "1178",
    "Wholesale Machinery": "1187",
    Caterers: "2212",
    "Mobile Food Services": "2214",
    "Renewable Energy Power Generation": "3240",
    "Bars, Taverns, and Nightclubs": "2217",
    "Renewable Energy Equipment Manufacturing": "3241",
    "Engineering Services": "3242",
    "Services for Renewable Energy": "3243",
    "Digital Accessibility Services": "3244",
    "Accessible Hardware Manufacturing": "3245",
    "Accessible Architecture and Design": "3246",
    "Robot Manufacturing": "3247",
    "Robotics Engineering": "3248",
    "Repair and Maintenance": "2225",
    "Surveying and Mapping Services": "3249",
    "Vehicle Repair and Maintenance": "2226",
    "Retail Pharmacies": "3250",
    "Climate Technology Product Manufacturing": "3251",
    "Climate Data and Analytics": "3252",
    "Alternative Fuel Vehicle Manufacturing": "3253",
    "Wholesale Recyclable Materials": "1206",
    "Smart Meter Manufacturing": "3254",
    "Fuel Cell Manufacturing": "3255",
    "Wholesale Luxury Goods and Jewelry": "1208",
    "Regenerative Design": "3256",
    "Wholesale Paper Products": "1212",
    "Electronic and Precision Equipment Maintenance": "2240",
    "Wholesale Drugs and Sundries": "1221",
    "Wholesale Apparel and Sewing Supplies": "1222",
    "Commercial and Industrial Machinery Maintenance": "2247",
    "Farming, Ranching, Forestry": "201",
    "Reupholstery and Furniture Repair": "2253",
    "Wholesale Footwear": "1230",
    "Wholesale Food and Beverage": "1231",
    "Footwear and Leather Goods Repair": "2255",
    "Personal and Laundry Services": "2258",
    "Personal Care Services": "2259",
    "Laundry and Drycleaning Services": "2272",
    "Wholesale Raw Farm Products": "1250",
    "Wholesale Chemical and Allied Products": "1257",
    "Pet Services": "2282",
    "Wholesale Petroleum and Petroleum Products": "1262",
    "Wholesale Alcoholic Beverages": "1267",
    "Ranching and Fisheries": "256",
    "Internet Marketplace Platforms": "1285",
    "Retail Motor Vehicles": "1292",
    "Household Services": "2318",
    "Retail Furniture and Home Furnishings": "1309",
    "Retail Appliances, Electrical, and Electronic Equipment": "1319",
    "Forestry and Logging": "298",
    "Retail Building Materials and Garden Equipment": "1324",
    "Health and Human Services": "2353",
    "Public Health": "2358",
    "Public Assistance Programs": "2360",
    "Food and Beverage Retail": "1339",
    "Air, Water, and Waste Program Management": "2366",
    "Conservation Programs": "2368",
    "Housing and Community Development": "2369",
    "Community Development and Urban Planning": "2374",
    "Economic Programs": "2375",
    "Oil, Gas, and Mining": "332",
    "Retail Health and Personal Care Products": "1359",
    "Coal Mining": "341",
    "Military and International Affairs": "2391",
    "Metal Ore Mining": "345",
    "Retail Gasoline": "1370",
    "Operations Consulting": "2401",
    "Nonmetallic Mineral Mining": "356",
    "Electric Power Transmission, Control, and Distribution": "382",
    "Retail Musical Instruments": "1407",
    "Electric Power Generation": "383",
    "Hydroelectric Power Generation": "384",
    "Retail Books and Printed News": "1409",
    "Fossil Fuel Electric Power Generation": "385",
    "Nuclear Electric Power Generation": "386",
    "Solar Electric Power Generation": "387",
    "Environmental Quality Programs": "388",
    "Geothermal Electric Power Generation": "389",
    "Biomass Electric Power Generation": "390",
    "Natural Gas Distribution": "397",
    "Water, Waste, Steam, and Air Conditioning Services": "398",
    "Retail Florists": "1423",
    "Retail Office Supplies and Gifts": "1424",
    "Water Supply and Irrigation Systems": "400",
    "Steam and Air-Conditioning Supply": "404",
    "Building Construction": "406",
    "Retail Recyclable Materials & Used Merchandise": "1431",
    "Residential Building Construction": "408",
    "Data Infrastructure and Analytics": "2458",
    "Nonresidential Building Construction": "413",
    "Utility System Construction": "419",
    "Electrical Equipment Manufacturing": "2468",
    "Online and Mail Order Retail": "1445",
    "Subdivision of Land": "428",
    "Highway, Street, and Bridge Construction": "431",
    "Specialty Trade Contractors": "435",
    "Building Structure and Exterior Contractors": "436",
    "Wind Electric Power Generation": "2489",
    Wineries: "2500",
    "Building Equipment Contractors": "453",
    "Rail Transportation": "1481",
    "Building Finishing Contractors": "460",
    "Ground Passenger Transportation": "1495",
    "Urban Transit Services": "1497",
    "Interurban and Rural Bus Services": "1504",
    "Taxi and Limousine Services": "1505",
    "Animal Feed Manufacturing": "481",
    "School and Employee Bus Services": "1512",
    "Shuttles and Special Needs Transportation Services": "1517",
    "Sugar and Confectionery Product Manufacturing": "495",
    "Pipeline Transportation": "1520",
    "Fruit and Vegetable Preserves Manufacturing": "504",
    "Sightseeing Transportation": "1532",
    "Meat Products Manufacturing": "521",
    "Seafood Product Manufacturing": "528",
    "Baked Goods Manufacturing": "529",
    "Postal Services": "1573",
    Breweries: "562",
    Distilleries: "564",
    "Technology, Information and Media": "1594",
    "Periodical Publishing": "1600",
    "Book Publishing": "1602",
    "Movies and Sound Recording": "1611",
    "Apparel Manufacturing": "598",
    "Sound Recording": "1623",
    "Sheet Music Publishing": "1625",
    "Radio and Television Broadcasting": "1633",
    "Fashion Accessories Manufacturing": "615",
    "Leather Product Manufacturing": "616",
    "Cable and Satellite Programming": "1641",
    "Telecommunications Carriers": "1644",
    "Footwear Manufacturing": "622",
    "Satellite Telecommunications": "1649",
    "Women's Handbag Manufacturing": "625",
    "Credit Intermediation": "1673",
    "Savings Institutions": "1678",
    "Loan Brokers": "1696",
    "Oil and Coal Product Manufacturing": "679",
    "Securities and Commodity Exchanges": "1713",
    "Chemical Raw Materials Manufacturing": "690",
    "Investment Advice": "1720",
    "Insurance Carriers": "1725",
    "Artificial Rubber and Synthetic Fiber Manufacturing": "703",
    "Agricultural Chemical Manufacturing": "709",
    "Insurance Agencies and Brokerages": "1737",
    "Claims Adjusting, Actuarial Services": "1738",
    "Funds and Trusts": "1742",
    "Insurance and Employee Benefit Funds": "1743",
    "Pension Funds": "1745",
    "Paint, Coating, and Adhesive Manufacturing": "722",
    "Trusts and Estates": "1750",
    "Soap and Cleaning Product Manufacturing": "727",
    "Real Estate and Equipment Rental Services": "1757",
    "Leasing Residential Real Estate": "1759",
    "Plastics and Rubber Product Manufacturing": "743",
    "Real Estate Agents and Brokers": "1770",
    "Equipment Rental Services": "1779",
    "Consumer Goods Rental": "1786",
    "Rubber Products Manufacturing": "763",
    "Clay and Refractory Products Manufacturing": "773",
    "Commercial and Industrial Equipment Rental": "1798",
    "Glass Product Manufacturing": "779",
    "Wood Product Manufacturing": "784",
    "Professional Services": "1810",
    "Lime and Gypsum Products Manufacturing": "794",
    "Abrasives and Nonmetallic Minerals Manufacturing": "799",
    "Primary Metal Manufacturing": "807",
    "IT System Design Services": "1855",
    "Marketing Services": "1862",
    "Fabricated Metal Products": "840",
    "Cutlery and Handtool Manufacturing": "849",
    "Architectural and Structural Metal Manufacturing": "852",
    "Boilers, Tanks, and Shipping Container Manufacturing": "861",
    "Construction Hardware Manufacturing": "871",
    "Spring and Wire Product Manufacturing": "873",
    "Turned Products and Fastener Manufacturing": "876",
    "Holding Companies": "1905",
    "Metal Treatments": "883",
    "Industry Associations": "1909",
    "Landscaping Services": "2934",
    "Professional Organizations": "1911",
    "Metal Valve, Ball, and Roller Manufacturing": "887",
    "Administrative and Support Services": "1912",
    "Office Administration": "1916",
    "Executive Search Services": "1923",
    "Temporary Help Services": "1925",
    "Agriculture, Construction, Mining Machinery Manufacturing": "901",
    "Telephone Call Centers": "1931",
    "Collection Agencies": "1938",
    "Commercial and Service Industry Machinery Manufacturing": "918",
    "HVAC and Refrigeration Equipment Manufacturing": "923",
    "Metalworking Machinery Manufacturing": "928",
    "Security Guards and Patrol Services": "1956",
    "Security Systems Services": "1958",
    "Engines and Power Transmission Equipment Manufacturing": "935",
    "Janitorial Services": "1965",
    "Waste Collection": "1981",
    "Waste Treatment and Disposal": "1986",
    "Communications Equipment Manufacturing": "964",
    "Audio and Video Equipment Manufacturing": "973",
    Education: "1999",
    "Measuring and Control Instrument Manufacturing": "983",
    "Secretarial Schools": "2012",
    "Technical and Vocational Training": "2018",
    "Magnetic and Optical Media Manufacturing": "994",
    "Cosmetology and Barber Schools": "2019",
    "Flight Training": "2020",
    "Electric Lighting Equipment Manufacturing": "998",
    "Fine Arts Schools": "2025",
    "Sports and Recreation Instruction": "2027",
    "Household Appliance Manufacturing": "1005",
    "Language Schools": "2029",
    Physicians: "2040",
    "Courts of Law": "3065",
    "Correctional Institutions": "3068",
    Dentists: "2045",
    "Fire Protection": "3070",
};

export const jobFunctionOptions = {
    "Business Development": "4",
    Engineering: "8",
    Accounting: "1",
    Administrative: "2",
    "Arts and Design": "3",
    "Community and Social Services": "5",
    Consulting: "6",
    Education: "7",
    Entrepreneurship: "9",
    Finance: "10",
    "Healthcare Services": "11",
    "Human Resources": "12",
    "Information Technology": "13",
    Legal: "14",
    Marketing: "15",
    "Media and Communication": "16",
    "Military and Protective Services": "17",
    Operations: "18",
    "Product Management": "19",
    "Program and Project Management": "20",
    Purchasing: "21",
    "Quality Assurance": "22",
    "Real Estate": "23",
    Research: "24",
    Sales: "25",
    "Customer Success and Support": "26",
};

export const specializationOptions = {
    Acupuncturist: "Acupuncturist",
    "Acute Care Clinical Nurse Specialist": "Acute Care Clinical Nurse Specialist",
    "Acute Care Nurse Practitioner": "Acute Care Nurse Practitioner",
    "Addiction Medicine (Anesthesiology) Physician": "Addiction Medicine (Anesthesiology) Physician",
    "Addiction Medicine (Family Medicine) Physician": "Addiction Medicine (Family Medicine) Physician",
    "Addiction Medicine (Internal Medicine) Physician": "Addiction Medicine (Internal Medicine) Physician",
    "Addiction Medicine (Preventive Medicine) Physician": "Addiction Medicine (Preventive Medicine) Physician",
    "Addiction Medicine (Psychiatry & Neurology) Physician": "Addiction Medicine (Psychiatry & Neurology) Physician",
    "Addiction Psychiatry Physician": "Addiction Psychiatry Physician",
    "Addiction (Substance Use Disorder) Counselor": "Addiction (Substance Use Disorder) Counselor",
    "Addiction (Substance Use Disorder) Psychologist": "Addiction (Substance Use Disorder) Psychologist",
    "Addiction (Substance Use Disorder) Registered Nurse": "Addiction (Substance Use Disorder) Registered Nurse",
    "Administrator Registered Nurse": "Administrator Registered Nurse",
    "Adolescent Medicine (Family Medicine) Physician": "Adolescent Medicine (Family Medicine) Physician",
    "Adolescent Medicine (Internal Medicine) Physician": "Adolescent Medicine (Internal Medicine) Physician",
    "Adult Companion": "Adult Companion",
    "Adult Congenital Heart Disease Physician": "Adult Congenital Heart Disease Physician",
    "Adult Development & Aging Psychologist": "Adult Development & Aging Psychologist",
    "Adult Health Clinical Nurse Specialist": "Adult Health Clinical Nurse Specialist",
    "Adult Health Nurse Practitioner": "Adult Health Nurse Practitioner",
    "Adult Medicine Physician": "Adult Medicine Physician",
    "Adult Psychiatric/Mental Health Clinical Nurse Specialist":
        "Adult Psychiatric/Mental Health Clinical Nurse Specialist",
    "Adult Psychiatric/Mental Health Registered Nurse": "Adult Psychiatric/Mental Health Registered Nurse",
    "Adult Reconstructive Orthopaedic Surgery Physician": "Adult Reconstructive Orthopaedic Surgery Physician",
    "Advanced Heart Failure and Transplant Cardiology Physician":
        "Advanced Heart Failure and Transplant Cardiology Physician",
    "Advanced Practice Dental Therapist": "Advanced Practice Dental Therapist",
    "Advanced Practice Midwife": "Advanced Practice Midwife",
    "Aerospace Medicine Physician": "Aerospace Medicine Physician",
    "Allergy & Immunology (Internal Medicine) Physician": "Allergy & Immunology (Internal Medicine) Physician",
    "Allergy & Immunology Physician": "Allergy & Immunology Physician",
    "Allergy Physician": "Allergy Physician",
    "Ambulatory Care Pharmacist": "Ambulatory Care Pharmacist",
    "Ambulatory Care Registered Nurse": "Ambulatory Care Registered Nurse",
    "Ambulatory Women's Health Care Registered Nurse": "Ambulatory Women's Health Care Registered Nurse",
    Anaplastologist: "Anaplastologist",
    "Anatomic Pathology & Clinical Pathology Physician": "Anatomic Pathology & Clinical Pathology Physician",
    "Anatomic Pathology Physician": "Anatomic Pathology Physician",
    "Anesthesiologist Assistant": "Anesthesiologist Assistant",
    "Anesthesiology Physician": "Anesthesiology Physician",
    "Art Therapist": "Art Therapist",
    "Assistant Behavior Analyst": "Assistant Behavior Analyst",
    "Assistant Health Information Record Technician": "Assistant Health Information Record Technician",
    "Assistive Technology Practitioner Audiologist": "Assistive Technology Practitioner Audiologist",
    "Assistive Technology Practitioner Rehabilitation Counselor":
        "Assistive Technology Practitioner Rehabilitation Counselor",
    "Assistive Technology Supplier Audiologist": "Assistive Technology Supplier Audiologist",
    "Assistive Technology Supplier Rehabilitation Counselor": "Assistive Technology Supplier Rehabilitation Counselor",
    "Athletic Trainer": "Athletic Trainer",
    "Attendant Care Provider": "Attendant Care Provider",
    Audiologist: "Audiologist",
    "Audiologist-Hearing Aid Fitter": "Audiologist-Hearing Aid Fitter",
    "Audiology Assistant": "Audiology Assistant",
    "Basic Emergency Medical Technician": "Basic Emergency Medical Technician",
    "Behavioral Analyst": "Behavioral Analyst",
    "Behavioral Neurology & Neuropsychiatry Physician": "Behavioral Neurology & Neuropsychiatry Physician",
    "Behavior Technician": "Behavior Technician",
    Biochemist: "Biochemist",
    "Biomedical Engineer": "Biomedical Engineer",
    "Biomedical Engineering Technician": "Biomedical Engineering Technician",
    "Biomedical Photographer": "Biomedical Photographer",
    Biostatiscian: "Biostatiscian",
    "Blind Rehabilitation Specialist/Technologist": "Blind Rehabilitation Specialist/Technologist",
    "Blood Banking Specialist/Technologist": "Blood Banking Specialist/Technologist",
    "Blood Banking & Transfusion Medicine Physician": "Blood Banking & Transfusion Medicine Physician",
    "Body Imaging Physician": "Body Imaging Physician",
    "Bone Densitometry Radiologic Technologist": "Bone Densitometry Radiologic Technologist",
    "Brain Injury Medicine (Physical Medicine & Rehabilitation) Physician":
        "Brain Injury Medicine (Physical Medicine & Rehabilitation) Physician",
    "Brain Injury Medicine (Psychiatry & Neurology) Physician":
        "Brain Injury Medicine (Psychiatry & Neurology) Physician",
    "Cardiac-Interventional Technology Radiologic Technologist":
        "Cardiac-Interventional Technology Radiologic Technologist",
    "Cardiac Rehabilitation Registered Nurse": "Cardiac Rehabilitation Registered Nurse",
    "Cardiology Pharmacist": "Cardiology Pharmacist",
    "Cardiology Technician": "Cardiology Technician",
    "Cardiopulmonary Physical Therapist": "Cardiopulmonary Physical Therapist",
    "Cardiovascular Disease Physician": "Cardiovascular Disease Physician",
    "Cardiovascular-Interventional Technology Radiologic Technologist":
        "Cardiovascular-Interventional Technology Radiologic Technologist",
    "Cardiovascular Invasive Specialist/Technologist": "Cardiovascular Invasive Specialist/Technologist",
    "Cardiovascular Specialist/Technologist": "Cardiovascular Specialist/Technologist",
    "Case Management Registered Nurse": "Case Management Registered Nurse",
    "Case Manager/Care Coordinator": "Case Manager/Care Coordinator",
    "Certified Registered Nurse Anesthetist": "Certified Registered Nurse Anesthetist",
    "Certified Respiratory Therapist": "Certified Respiratory Therapist",
    "Chemical Pathology Physician": "Chemical Pathology Physician",
    "Chemistry Pathology Specialist/Technologist": "Chemistry Pathology Specialist/Technologist",
    "Child Abuse Pediatrics Physician": "Child Abuse Pediatrics Physician",
    "Child & Adolescent Psychiatric/Mental Health Clinical Nurse Specialist":
        "Child & Adolescent Psychiatric/Mental Health Clinical Nurse Specialist",
    "Child & Adolescent Psychiatric/Mental Health Registered Nurse":
        "Child & Adolescent Psychiatric/Mental Health Registered Nurse",
    "Child & Adolescent Psychiatry Physician": "Child & Adolescent Psychiatry Physician",
    "Child & Family Psychiatric/Mental Health Clinical Nurse Specialist":
        "Child & Family Psychiatric/Mental Health Clinical Nurse Specialist",
    Chiropractor: "Chiropractor",
    "Chore Provider": "Chore Provider",
    "Chronically Ill Psychiatric/Mental Health Clinical Nurse Specialist":
        "Chronically Ill Psychiatric/Mental Health Clinical Nurse Specialist",
    "Chronic Care Clinical Nurse Specialist": "Chronic Care Clinical Nurse Specialist",
    "Clinical Biochemical Genetics Physician": "Clinical Biochemical Genetics Physician",
    "Clinical Cardiac Electrophysiology Physician": "Clinical Cardiac Electrophysiology Physician",
    "Clinical Child & Adolescent Psychologist": "Clinical Child & Adolescent Psychologist",
    "Clinical Cytogenetics Physician": "Clinical Cytogenetics Physician",
    "Clinical Electrophysiology Physical Therapist": "Clinical Electrophysiology Physical Therapist",
    "Clinical Ethicist": "Clinical Ethicist",
    "Clinical Exercise Physiologist": "Clinical Exercise Physiologist",
    "Clinical Genetics (M.D.) Physician": "Clinical Genetics (M.D.) Physician",
    "Clinical Informatics (Pathology) Physician": "Clinical Informatics (Pathology) Physician",
    "Clinical Informatics Physician": "Clinical Informatics Physician",
    "Clinical & Laboratory Dermatological Immunology Physician":
        "Clinical & Laboratory Dermatological Immunology Physician",
    "Clinical Laboratory Director (Non-physician)": "Clinical Laboratory Director (Non-physician)",
    "Clinical & Laboratory Immunology (Allergy & Immunology) Physician":
        "Clinical & Laboratory Immunology (Allergy & Immunology) Physician",
    "Clinical & Laboratory Immunology (Internal Medicine) Physician":
        "Clinical & Laboratory Immunology (Internal Medicine) Physician",
    "Clinical Molecular Genetics Physician": "Clinical Molecular Genetics Physician",
    "Clinical Neurophysiology Physician": "Clinical Neurophysiology Physician",
    "Clinical Neuropsychologist": "Clinical Neuropsychologist",
    "Clinical Nurse Specialist": "Clinical Nurse Specialist",
    "Clinical Pathology/Laboratory Medicine Physician": "Clinical Pathology/Laboratory Medicine Physician",
    "Clinical Pathology Physician": "Clinical Pathology Physician",
    "Clinical Pharmacology Physician": "Clinical Pharmacology Physician",
    "Clinical Psychologist": "Clinical Psychologist",
    "Clinical Social Worker": "Clinical Social Worker",
    "Cognitive & Behavioral Psychologist": "Cognitive & Behavioral Psychologist",
    "College Health Registered Nurse": "College Health Registered Nurse",
    "Colon & Rectal Surgery Physician": "Colon & Rectal Surgery Physician",
    "Community Health Nurse Practitioner": "Community Health Nurse Practitioner",
    "Community Health/Public Health Clinical Nurse Specialist":
        "Community Health/Public Health Clinical Nurse Specialist",
    "Community Health Registered Nurse": "Community Health Registered Nurse",
    "Community Health Worker": "Community Health Worker",
    "Community Psychiatric/Mental Health Clinical Nurse Specialist":
        "Community Psychiatric/Mental Health Clinical Nurse Specialist",
    "Complex Family Planning Physician": "Complex Family Planning Physician",
    "Compounded Sterile Preparations Pharmacist": "Compounded Sterile Preparations Pharmacist",
    "Computed Tomography Radiologic Technologist": "Computed Tomography Radiologic Technologist",
    "Contact Lens Fitter": "Contact Lens Fitter",
    "Contact Lens Technician/Technologist": "Contact Lens Technician/Technologist",
    "Continence Care Registered Nurse": "Continence Care Registered Nurse",
    "Continuing Education/Staff Development Registered Nurse":
        "Continuing Education/Staff Development Registered Nurse",
    Contractor: "Contractor",
    "Cornea and External Diseases Specialist Physician": "Cornea and External Diseases Specialist Physician",
    "Corneal and Contact Management Optometrist": "Corneal and Contact Management Optometrist",
    "Counseling Psychologist": "Counseling Psychologist",
    Counselor: "Counselor",
    "Critical Care Certified Respiratory Therapist": "Critical Care Certified Respiratory Therapist",
    "Critical Care Medicine (Anesthesiology) Physician": "Critical Care Medicine (Anesthesiology) Physician",
    "Critical Care Medicine Clinical Nurse Specialist": "Critical Care Medicine Clinical Nurse Specialist",
    "Critical Care Medicine (Internal Medicine) Physician": "Critical Care Medicine (Internal Medicine) Physician",
    "Critical Care Medicine Nurse Practitioner": "Critical Care Medicine Nurse Practitioner",
    "Critical Care Medicine (Obstetrics & Gynecology) Physician":
        "Critical Care Medicine (Obstetrics & Gynecology) Physician",
    "Critical Care Medicine Registered Nurse": "Critical Care Medicine Registered Nurse",
    "Critical Care Neonatal Nurse Practitioner": "Critical Care Neonatal Nurse Practitioner",
    "Critical Care Pediatric Nurse Practitioner": "Critical Care Pediatric Nurse Practitioner",
    "Critical Care Pharmacist": "Critical Care Pharmacist",
    "Critical Care Registered Respiratory Therapist": "Critical Care Registered Respiratory Therapist",
    "Cytopathology Physician": "Cytopathology Physician",
    "Cytotechnology Specialist/Technologist": "Cytotechnology Specialist/Technologist",
    "Dance Therapist": "Dance Therapist",
    "Darkroom Technician": "Darkroom Technician",
    "Day Training/Habilitation Specialist": "Day Training/Habilitation Specialist",
    "Dental Assistant": "Dental Assistant",
    "Dental Hygienist": "Dental Hygienist",
    "Dental Laboratory Technician": "Dental Laboratory Technician",
    "Dental Therapist": "Dental Therapist",
    Dentist: "Dentist",
    "Dentist Anesthesiologist": "Dentist Anesthesiologist",
    Denturist: "Denturist",
    "Dermatology Physician": "Dermatology Physician",
    "Dermatopathology (Pathology) Physician": "Dermatopathology (Pathology) Physician",
    "Dermatopathology Physician": "Dermatopathology Physician",
    "Developmental - Behavioral Pediatrics Physician": "Developmental - Behavioral Pediatrics Physician",
    "Developmental Therapist": "Developmental Therapist",
    "Diabetes Educator Registered Nurse": "Diabetes Educator Registered Nurse",
    "Diagnostic Neuroimaging (Psychiatry & Neurology) Physician":
        "Diagnostic Neuroimaging (Psychiatry & Neurology) Physician",
    "Diagnostic Neuroimaging (Radiology) Physician": "Diagnostic Neuroimaging (Radiology) Physician",
    "Diagnostic Radiology Physician": "Diagnostic Radiology Physician",
    "Diagnostic Ultrasound Physician": "Diagnostic Ultrasound Physician",
    "Dietary Manager": "Dietary Manager",
    "Diplomate Laboratory Management Specialist/Technologist":
        "Diplomate Laboratory Management Specialist/Technologist",
    Doula: "Doula",
    "Drama Therapist": "Drama Therapist",
    Driver: "Driver",
    "Driving and Community Mobility Occupational Therapist": "Driving and Community Mobility Occupational Therapist",
    "Driving and Community Mobility Occupational Therapy Assistant":
        "Driving and Community Mobility Occupational Therapy Assistant",
    "Educational Certified Respiratory Therapist": "Educational Certified Respiratory Therapist",
    "Educational Registered Respiratory Therapist": "Educational Registered Respiratory Therapist",
    "EEG Specialist/Technologist": "EEG Specialist/Technologist",
    "EEG Technician": "EEG Technician",
    "Electrodiagnostic Medicine Physician": "Electrodiagnostic Medicine Physician",
    "Electroneurodiagnostic Specialist/Technologist": "Electroneurodiagnostic Specialist/Technologist",
    "Emergency Care Certified Respiratory Therapist": "Emergency Care Certified Respiratory Therapist",
    "Emergency Care Registered Respiratory Therapist": "Emergency Care Registered Respiratory Therapist",
    "Emergency Clinical Nurse Specialist": "Emergency Clinical Nurse Specialist",
    "Emergency Medical Services (Emergency Medicine) Physician":
        "Emergency Medical Services (Emergency Medicine) Physician",
    "Emergency Medicine Pharmacist": "Emergency Medicine Pharmacist",
    "Emergency Medicine Physician": "Emergency Medicine Physician",
    "Emergency Registered Nurse": "Emergency Registered Nurse",
    "Endocrinology, Diabetes & Metabolism Physician": "Endocrinology, Diabetes & Metabolism Physician",
    Endodontist: "Endodontist",
    "Enterostomal Therapy Registered Nurse": "Enterostomal Therapy Registered Nurse",
    "Environmental Modification Occupational Therapist": "Environmental Modification Occupational Therapist",
    "Environmental Modification Occupational Therapy Assistant":
        "Environmental Modification Occupational Therapy Assistant",
    "Epilepsy Physician": "Epilepsy Physician",
    "Ergonomics Occupational Therapist": "Ergonomics Occupational Therapist",
    "Ergonomics Physical Therapist": "Ergonomics Physical Therapist",
    "Ethics Clinical Nurse Specialist": "Ethics Clinical Nurse Specialist",
    "Exercise & Sports Psychologist": "Exercise & Sports Psychologist",
    "Facial Plastic Surgery Physician": "Facial Plastic Surgery Physician",
    "Family Health Clinical Nurse Specialist": "Family Health Clinical Nurse Specialist",
    "Family Medicine Physician": "Family Medicine Physician",
    "Family Nurse Practitioner": "Family Nurse Practitioner",
    "Family Psychologist": "Family Psychologist",
    "Feeding, Eating & Swallowing Occupational Therapist": "Feeding, Eating & Swallowing Occupational Therapist",
    "Feeding, Eating & Swallowing Occupational Therapy Assistant":
        "Feeding, Eating & Swallowing Occupational Therapy Assistant",
    "Female Pelvic Medicine and Reconstructive Surgery (Obstetrics & Gynecology) Physician":
        "Female Pelvic Medicine and Reconstructive Surgery (Obstetrics & Gynecology) Physician",
    "Female Pelvic Medicine and Reconstructive Surgery (Urology) Physician":
        "Female Pelvic Medicine and Reconstructive Surgery (Urology) Physician",
    "Flight Registered Nurse": "Flight Registered Nurse",
    "Foot & Ankle Surgery Podiatrist": "Foot & Ankle Surgery Podiatrist",
    "Foot Surgery Podiatrist": "Foot Surgery Podiatrist",
    "Forensic Pathology Physician": "Forensic Pathology Physician",
    "Forensic Psychiatry Physician": "Forensic Psychiatry Physician",
    "Forensic Psychologist": "Forensic Psychologist",
    "Funeral Director": "Funeral Director",
    "Gastroenterology Physician": "Gastroenterology Physician",
    "Gastroenterology Registered Nurse": "Gastroenterology Registered Nurse",
    "General Care Certified Respiratory Therapist": "General Care Certified Respiratory Therapist",
    "General Care Registered Respiratory Therapist": "General Care Registered Respiratory Therapist",
    "General Practice Dentistry": "General Practice Dentistry",
    "General Practice Physician": "General Practice Physician",
    "General Practice Registered Nurse": "General Practice Registered Nurse",
    "Genetic Counselor (M.S.)": "Genetic Counselor (M.S.)",
    "Geriatric Care Certified Respiratory Therapist": "Geriatric Care Certified Respiratory Therapist",
    "Geriatric Care Registered Respiratory Therapist": "Geriatric Care Registered Respiratory Therapist",
    "Geriatric Medicine (Family Medicine) Physician": "Geriatric Medicine (Family Medicine) Physician",
    "Geriatric Medicine (Internal Medicine) Physician": "Geriatric Medicine (Internal Medicine) Physician",
    "Geriatric Pharmacist": "Geriatric Pharmacist",
    "Geriatric Physical Therapist": "Geriatric Physical Therapist",
    "Geriatric Psychiatry Physician": "Geriatric Psychiatry Physician",
    "Gerontological Nutrition Registered Dietitian": "Gerontological Nutrition Registered Dietitian",
    "Gerontology Clinical Nurse Specialist": "Gerontology Clinical Nurse Specialist",
    "Gerontology Nurse Practitioner": "Gerontology Nurse Practitioner",
    "Gerontology Occupational Therapist": "Gerontology Occupational Therapist",
    "Gerontology Registered Nurse": "Gerontology Registered Nurse",
    "Geropsychiatric Psychiatric/Mental Health Clinical Nurse Specialist":
        "Geropsychiatric Psychiatric/Mental Health Clinical Nurse Specialist",
    "Glaucoma Specialist (Ophthalmology) Physician": "Glaucoma Specialist (Ophthalmology) Physician",
    "Graphics Designer": "Graphics Designer",
    "Graphics Methods Specialist/Technologist": "Graphics Methods Specialist/Technologist",
    "Group Psychotherapy Psychologist": "Group Psychotherapy Psychologist",
    "Gynecologic Oncology Physician": "Gynecologic Oncology Physician",
    "Gynecology Physician": "Gynecology Physician",
    "Hand Occupational Therapist": "Hand Occupational Therapist",
    "Hand Physical Therapist": "Hand Physical Therapist",
    "Health Educator": "Health Educator",
    "Health Information Specialist/Technologist": "Health Information Specialist/Technologist",
    "Health Information Technician": "Health Information Technician",
    "Health Psychologist": "Health Psychologist",
    "Health Service Psychologist": "Health Service Psychologist",
    "Health & Wellness Coach": "Health & Wellness Coach",
    "Hearing Instrument Specialist": "Hearing Instrument Specialist",
    "Hemapheresis Practitioner": "Hemapheresis Practitioner",
    "Hematology (Internal Medicine) Physician": "Hematology (Internal Medicine) Physician",
    "Hematology & Oncology Physician": "Hematology & Oncology Physician",
    "Hematology (Pathology) Physician": "Hematology (Pathology) Physician",
    "Hematology Specialist/Technologist": "Hematology Specialist/Technologist",
    "Hemodialysis Registered Nurse": "Hemodialysis Registered Nurse",
    "Hepatology Physician": "Hepatology Physician",
    "High-Risk Obstetric Registered Nurse": "High-Risk Obstetric Registered Nurse",
    "Histology Specialist/Technologist": "Histology Specialist/Technologist",
    "Histology Technician": "Histology Technician",
    "Holistic Clinical Nurse Specialist": "Holistic Clinical Nurse Specialist",
    "Home Health Aide": "Home Health Aide",
    "Home Health Certified Respiratory Therapist": "Home Health Certified Respiratory Therapist",
    "Home Health Clinical Nurse Specialist": "Home Health Clinical Nurse Specialist",
    "Home Health Registered Nurse": "Home Health Registered Nurse",
    "Home Health Registered Respiratory Therapist": "Home Health Registered Respiratory Therapist",
    Homemaker: "Homemaker",
    "Home Modifications Contractor": "Home Modifications Contractor",
    Homeopath: "Homeopath",
    "Hospice and Palliative Medicine (Anesthesiology) Physician":
        "Hospice and Palliative Medicine (Anesthesiology) Physician",
    "Hospice and Palliative Medicine (Emergency Medicine) Physician":
        "Hospice and Palliative Medicine (Emergency Medicine) Physician",
    "Hospice and Palliative Medicine (Family Medicine) Physician":
        "Hospice and Palliative Medicine (Family Medicine) Physician",
    "Hospice and Palliative Medicine (Internal Medicine) Physician":
        "Hospice and Palliative Medicine (Internal Medicine) Physician",
    "Hospice and Palliative Medicine (Obstetrics & Gynecology) Physician":
        "Hospice and Palliative Medicine (Obstetrics & Gynecology) Physician",
    "Hospice and Palliative Medicine (Physical Medicine & Rehabilitation) Physician":
        "Hospice and Palliative Medicine (Physical Medicine & Rehabilitation) Physician",
    "Hospice and Palliative Medicine (Psychiatry & Neurology) Physician":
        "Hospice and Palliative Medicine (Psychiatry & Neurology) Physician",
    "Hospice and Palliative Medicine (Radiology) Physician": "Hospice and Palliative Medicine (Radiology) Physician",
    "Hospice and Palliative Medicine (Surgery) Physician": "Hospice and Palliative Medicine (Surgery) Physician",
    "Hospice Registered Nurse": "Hospice Registered Nurse",
    "Hospital Based Coding Specialist": "Hospital Based Coding Specialist",
    "Hospitalist Physician": "Hospitalist Physician",
    "Human Factors Occupational Therapist": "Human Factors Occupational Therapist",
    "Human Factors Physical Therapist": "Human Factors Physical Therapist",
    "Hypertension Specialist Physician": "Hypertension Specialist Physician",
    "Immunology Pathology Specialist/Technologist": "Immunology Pathology Specialist/Technologist",
    "Immunopathology Physician": "Immunopathology Physician",
    "Independent Duty Corpsman": "Independent Duty Corpsman",
    "Independent Duty Medical Technicians": "Independent Duty Medical Technicians",
    "Independent Medical Examiner Chiropractor": "Independent Medical Examiner Chiropractor",
    "Independent Medical Examiner Physician": "Independent Medical Examiner Physician",
    "Infection Control Registered Nurse": "Infection Control Registered Nurse",
    "Infectious Disease Physician": "Infectious Disease Physician",
    "Infectious Diseases Pharmacist": "Infectious Diseases Pharmacist",
    "Informatics Clinical Nurse Specialist": "Informatics Clinical Nurse Specialist",
    "Infusion Therapy Registered Nurse": "Infusion Therapy Registered Nurse",
    "Inpatient Obstetric Registered Nurse": "Inpatient Obstetric Registered Nurse",
    "Integrative Medicine Physician": "Integrative Medicine Physician",
    "Intellectual & Developmental Disabilities Psychologist": "Intellectual & Developmental Disabilities Psychologist",
    "Intermediate Emergency Medical Technician": "Intermediate Emergency Medical Technician",
    "Internal Medicine Physician": "Internal Medicine Physician",
    "Internist Chiropractor": "Internist Chiropractor",
    Interpreter: "Interpreter",
    "Interventional Cardiology Physician": "Interventional Cardiology Physician",
    "Interventional Pain Medicine Physician": "Interventional Pain Medicine Physician",
    "In Vivo & In Vitro Nuclear Medicine Physician": "In Vivo & In Vitro Nuclear Medicine Physician",
    Kinesiotherapist: "Kinesiotherapist",
    "Laboratory Management Specialist/Technologist": "Laboratory Management Specialist/Technologist",
    "Lactation Consultant (Non-RN)": "Lactation Consultant (Non-RN)",
    "Lactation Consultant (Registered Nurse)": "Lactation Consultant (Registered Nurse)",
    "Lay Midwife": "Lay Midwife",
    "Legal Medicine": "Legal Medicine",
    "Legal Medicine (M.D./D.O.) Physician": "Legal Medicine (M.D./D.O.) Physician",
    "Licensed Practical Nurse": "Licensed Practical Nurse",
    "Licensed Psychiatric Technician": "Licensed Psychiatric Technician",
    "Licensed Vocational Nurse": "Licensed Vocational Nurse",
    "Long-Term Care Clinical Nurse Specialist": "Long-Term Care Clinical Nurse Specialist",
    "Low-Risk Neonatal Registered Nurse": "Low-Risk Neonatal Registered Nurse",
    "Low Vision Occupational Therapist": "Low Vision Occupational Therapist",
    "Low Vision Occupational Therapy Assistant": "Low Vision Occupational Therapy Assistant",
    "Low Vision Rehabilitation Optometrist": "Low Vision Rehabilitation Optometrist",
    "Magnetic Resonance Imaging (MRI) Internal Medicine Physician":
        "Magnetic Resonance Imaging (MRI) Internal Medicine Physician",
    "Magnetic Resonance Imaging Radiologic Technologist": "Magnetic Resonance Imaging Radiologic Technologist",
    "Mammography Radiologic Technologist": "Mammography Radiologic Technologist",
    "Marriage & Family Therapist": "Marriage & Family Therapist",
    "Massage Therapist": "Massage Therapist",
    "Mastectomy Fitter": "Mastectomy Fitter",
    "Maternal & Fetal Medicine Physician": "Maternal & Fetal Medicine Physician",
    "Maternal Newborn Registered Nurse": "Maternal Newborn Registered Nurse",
    Mechanotherapist: "Mechanotherapist",
    "Medical Art Specialist/Technologist": "Medical Art Specialist/Technologist",
    "Medical Biochemical Genetics": "Medical Biochemical Genetics",
    "Medical Geneticist (PhD) Specialist/Technologist": "Medical Geneticist (PhD) Specialist/Technologist",
    "Medical Illustrator": "Medical Illustrator",
    "Medical Laboratory Technician": "Medical Laboratory Technician",
    "Medical Microbiology Physician": "Medical Microbiology Physician",
    "Medical Oncology Physician": "Medical Oncology Physician",
    "Medical Physician Assistant": "Medical Physician Assistant",
    "Medical Research Veterinarian": "Medical Research Veterinarian",
    "Medical-Surgical Clinical Nurse Specialist": "Medical-Surgical Clinical Nurse Specialist",
    "Medical-Surgical Registered Nurse": "Medical-Surgical Registered Nurse",
    "Medical Technologist": "Medical Technologist",
    "Medical Toxicology (Emergency Medicine) Physician": "Medical Toxicology (Emergency Medicine) Physician",
    "Medical Toxicology (Preventive Medicine) Physician": "Medical Toxicology (Preventive Medicine) Physician",
    "Mental Health Counselor": "Mental Health Counselor",
    "Mental Health Occupational Therapist": "Mental Health Occupational Therapist",
    "Metabolic Nutrition Registered Dietitian": "Metabolic Nutrition Registered Dietitian",
    "Microbiology Specialist/Technologist": "Microbiology Specialist/Technologist",
    Midwife: "Midwife",
    "Military Health Care Provider": "Military Health Care Provider",
    "MOHS-Micrographic Surgery Physician": "MOHS-Micrographic Surgery Physician",
    "Molecular Genetic Pathology (Medical Genetics) Physician":
        "Molecular Genetic Pathology (Medical Genetics) Physician",
    "Molecular Genetic Pathology (Pathology) Physician": "Molecular Genetic Pathology (Pathology) Physician",
    "Multi-Specialty Group": "Multi-Specialty Group",
    "Music Therapist": "Music Therapist",
    Naprapath: "Naprapath",
    Naturopath: "Naturopath",
    "Neonatal Clinical Nurse Specialist": "Neonatal Clinical Nurse Specialist",
    "Neonatal Intensive Care Registered Nurse": "Neonatal Intensive Care Registered Nurse",
    "Neonatal Nurse Practitioner": "Neonatal Nurse Practitioner",
    "Neonatal/Pediatric Certified Respiratory Therapist": "Neonatal/Pediatric Certified Respiratory Therapist",
    "Neonatal/Pediatric Registered Respiratory Therapist": "Neonatal/Pediatric Registered Respiratory Therapist",
    "Neonatal-Perinatal Medicine Physician": "Neonatal-Perinatal Medicine Physician",
    "Nephrology Physician": "Nephrology Physician",
    "Nephrology Registered Nurse": "Nephrology Registered Nurse",
    "Nephrology Specialist/Technologist": "Nephrology Specialist/Technologist",
    "Neurocritical Care Physician": "Neurocritical Care Physician",
    "Neurodevelopmental Disabilities Physician": "Neurodevelopmental Disabilities Physician",
    "Neurological Surgery Physician": "Neurological Surgery Physician",
    "Neurology Chiropractor": "Neurology Chiropractor",
    "Neurology Physical Therapist": "Neurology Physical Therapist",
    "Neurology Physician": "Neurology Physician",
    "Neurology with Special Qualifications in Child Neurology Physician":
        "Neurology with Special Qualifications in Child Neurology Physician",
    "Neuromuscular Medicine (Physical Medicine & Rehabilitation) Physician":
        "Neuromuscular Medicine (Physical Medicine & Rehabilitation) Physician",
    "Neuromuscular Medicine (Psychiatry & Neurology) Physician":
        "Neuromuscular Medicine (Psychiatry & Neurology) Physician",
    "Neuromusculoskeletal Medicine & OMM Physician": "Neuromusculoskeletal Medicine & OMM Physician",
    "Neuro-ophthalmology Physician": "Neuro-ophthalmology Physician",
    "Neuropathology Physician": "Neuropathology Physician",
    "Neuroradiology Physician": "Neuroradiology Physician",
    "Neurorehabilitation Occupational Therapist": "Neurorehabilitation Occupational Therapist",
    "Neuroscience Clinical Nurse Specialist": "Neuroscience Clinical Nurse Specialist",
    "Neuroscience Registered Nurse": "Neuroscience Registered Nurse",
    "Nuclear Cardiology Physician": "Nuclear Cardiology Physician",
    "Nuclear Imaging & Therapy Physician": "Nuclear Imaging & Therapy Physician",
    "Nuclear Medicine Physician": "Nuclear Medicine Physician",
    "Nuclear Medicine Technology Radiologic Technologist": "Nuclear Medicine Technology Radiologic Technologist",
    "Nuclear Pharmacist": "Nuclear Pharmacist",
    "Nuclear Radiology Physician": "Nuclear Radiology Physician",
    "Nurse Massage Therapist (NMT)": "Nurse Massage Therapist (NMT)",
    "Nurse Practitioner": "Nurse Practitioner",
    "Nurse's Aide": "Nurse's Aide",
    "Nursing Home Administrator": "Nursing Home Administrator",
    "Nutrition Chiropractor": "Nutrition Chiropractor",
    "Nutrition Education Nutritionist": "Nutrition Education Nutritionist",
    Nutritionist: "Nutritionist",
    "Nutrition Support Pharmacist": "Nutrition Support Pharmacist",
    "Nutrition Support Registered Nurse": "Nutrition Support Registered Nurse",
    "Obesity and Weight Management Nutrition Registered Dietitian":
        "Obesity and Weight Management Nutrition Registered Dietitian",
    "Obesity Medicine (Family Medicine) Physician": "Obesity Medicine (Family Medicine) Physician",
    "Obesity Medicine (Internal Medicine) Physician": "Obesity Medicine (Internal Medicine) Physician",
    "Obesity Medicine (Obstetrics & Gynecology) Physician": "Obesity Medicine (Obstetrics & Gynecology) Physician",
    "Obesity Medicine (Preventive Medicine) Physician": "Obesity Medicine (Preventive Medicine) Physician",
    "Obesity Medicine (Psychiatry & Neurology) Physician": "Obesity Medicine (Psychiatry & Neurology) Physician",
    "Obstetrics & Gynecology Nurse Practitioner": "Obstetrics & Gynecology Nurse Practitioner",
    "Obstetrics & Gynecology Physician": "Obstetrics & Gynecology Physician",
    "Obstetrics Physician": "Obstetrics Physician",
    "Occupational Health Chiropractor": "Occupational Health Chiropractor",
    "Occupational Health Clinical Nurse Specialist": "Occupational Health Clinical Nurse Specialist",
    "Occupational Health Nurse Practitioner": "Occupational Health Nurse Practitioner",
    "Occupational Health Registered Nurse": "Occupational Health Registered Nurse",
    "Occupational Medicine Physician": "Occupational Medicine Physician",
    "Occupational Therapist": "Occupational Therapist",
    "Occupational Therapy Assistant": "Occupational Therapy Assistant",
    "Occupational Vision Optometrist": "Occupational Vision Optometrist",
    Ocularist: "Ocularist",
    "Oncology Clinical Nurse Specialist": "Oncology Clinical Nurse Specialist",
    "Oncology Nutrition Registered Dietitian": "Oncology Nutrition Registered Dietitian",
    "Oncology Pharmacist": "Oncology Pharmacist",
    "Oncology Registered Nurse": "Oncology Registered Nurse",
    "Ophthalmic Assistant": "Ophthalmic Assistant",
    "Ophthalmic Plastic and Reconstructive Surgery Physician":
        "Ophthalmic Plastic and Reconstructive Surgery Physician",
    "Ophthalmic Registered Nurse": "Ophthalmic Registered Nurse",
    "Ophthalmic Technician/Technologist": "Ophthalmic Technician/Technologist",
    "Ophthalmology Physician": "Ophthalmology Physician",
    Optician: "Optician",
    "Optometric Assistant Technician": "Optometric Assistant Technician",
    "Optometric Technician": "Optometric Technician",
    Optometrist: "Optometrist",
    "Oral and Maxillofacial Pathology Dentist": "Oral and Maxillofacial Pathology Dentist",
    "Oral and Maxillofacial Radiology Dentist": "Oral and Maxillofacial Radiology Dentist",
    "Oral and Maxillofacial Surgery (Dentist)": "Oral and Maxillofacial Surgery (Dentist)",
    "Oral & Maxillofacial Surgery (D.M.D.)": "Oral & Maxillofacial Surgery (D.M.D.)",
    "Oral Medicinist": "Oral Medicinist",
    "Orientation and Mobility Training Rehabilitation Counselor":
        "Orientation and Mobility Training Rehabilitation Counselor",
    "Orofacial Pain Dentist": "Orofacial Pain Dentist",
    "Orthodontics and Dentofacial Orthopedic Dentist": "Orthodontics and Dentofacial Orthopedic Dentist",
    "Orthopaedic Foot and Ankle Surgery Physician": "Orthopaedic Foot and Ankle Surgery Physician",
    "Orthopaedic Hand Surgery Physician": "Orthopaedic Hand Surgery Physician",
    "Orthopaedic Surgery of the Spine Physician": "Orthopaedic Surgery of the Spine Physician",
    "Orthopaedic Surgery Physician": "Orthopaedic Surgery Physician",
    "Orthopaedic Trauma Physician": "Orthopaedic Trauma Physician",
    "Orthopedic Assistant": "Orthopedic Assistant",
    "Orthopedic Chiropractor": "Orthopedic Chiropractor",
    "Orthopedic Physical Therapist": "Orthopedic Physical Therapist",
    "Orthopedic Registered Nurse": "Orthopedic Registered Nurse",
    Orthoptist: "Orthoptist",
    "Orthotic Fitter": "Orthotic Fitter",
    Orthotist: "Orthotist",
    "Ostomy Care Registered Nurse": "Ostomy Care Registered Nurse",
    "Other Specialist/Technologist": "Other Specialist/Technologist",
    "Other Technician": "Other Technician",
    "Otolaryngic Allergy Physician": "Otolaryngic Allergy Physician",
    "Otolaryngology/Facial Plastic Surgery Physician": "Otolaryngology/Facial Plastic Surgery Physician",
    "Otolaryngology Physician": "Otolaryngology Physician",
    "Otology & Neurotology Physician": "Otology & Neurotology Physician",
    "Otorhinolaryngology & Head-Neck Registered Nurse": "Otorhinolaryngology & Head-Neck Registered Nurse",
    "Pain Management Registered Nurse": "Pain Management Registered Nurse",
    "Pain Medicine (Anesthesiology) Physician": "Pain Medicine (Anesthesiology) Physician",
    "Pain Medicine (Physical Medicine & Rehabilitation) Physician":
        "Pain Medicine (Physical Medicine & Rehabilitation) Physician",
    "Pain Medicine Physician": "Pain Medicine Physician",
    "Pain Medicine (Psychiatry & Neurology) Physician": "Pain Medicine (Psychiatry & Neurology) Physician",
    "Palliative/Hospice Certified Respiratory Therapist": "Palliative/Hospice Certified Respiratory Therapist",
    "Palliative/Hospice Registered Respiratory Therapist": "Palliative/Hospice Registered Respiratory Therapist",
    Paramedic: "Paramedic",
    "Pastoral Counselor": "Pastoral Counselor",
    "Pathology Specialist/Technologist": "Pathology Specialist/Technologist",
    "Pathology Technician": "Pathology Technician",
    "Patient Transport Certified Respiratory Therapist": "Patient Transport Certified Respiratory Therapist",
    "Patient Transport Registered Respiratory Therapist": "Patient Transport Registered Respiratory Therapist",
    "Pediatric Adolescent Medicine Physician": "Pediatric Adolescent Medicine Physician",
    "Pediatric Allergy/Immunology Physician": "Pediatric Allergy/Immunology Physician",
    "Pediatric Anesthesiology Physician": "Pediatric Anesthesiology Physician",
    "Pediatric Cardiology Physician": "Pediatric Cardiology Physician",
    "Pediatric Chiropractor": "Pediatric Chiropractor",
    "Pediatric Clinical & Laboratory Immunology Physician": "Pediatric Clinical & Laboratory Immunology Physician",
    "Pediatric Clinical Nurse Specialist": "Pediatric Clinical Nurse Specialist",
    "Pediatric Critical Care Medicine Physician": "Pediatric Critical Care Medicine Physician",
    "Pediatric Critical Care Nutrition Registered Dietitian": "Pediatric Critical Care Nutrition Registered Dietitian",
    "Pediatric Dentist": "Pediatric Dentist",
    "Pediatric Dermatology Physician": "Pediatric Dermatology Physician",
    "Pediatric Emergency Medicine (Emergency Medicine) Physician":
        "Pediatric Emergency Medicine (Emergency Medicine) Physician",
    "Pediatric Emergency Medicine (Pediatrics) Physician": "Pediatric Emergency Medicine (Pediatrics) Physician",
    "Pediatric Endocrinology Physician": "Pediatric Endocrinology Physician",
    "Pediatric Gastroenterology Physician": "Pediatric Gastroenterology Physician",
    "Pediatric Hematology & Oncology Physician": "Pediatric Hematology & Oncology Physician",
    "Pediatric Hospice and Palliative Medicine Physician": "Pediatric Hospice and Palliative Medicine Physician",
    "Pediatric Infectious Diseases Physician": "Pediatric Infectious Diseases Physician",
    "Pediatric Medical Toxicology Physician": "Pediatric Medical Toxicology Physician",
    "Pediatric Nephrology Physician": "Pediatric Nephrology Physician",
    "Pediatric Neurodevelopmental Disabilities Physician": "Pediatric Neurodevelopmental Disabilities Physician",
    "Pediatric Nurse Practitioner": "Pediatric Nurse Practitioner",
    "Pediatric Nutrition Registered Dietitian": "Pediatric Nutrition Registered Dietitian",
    "Pediatric Obesity Medicine Physician": "Pediatric Obesity Medicine Physician",
    "Pediatric Occupational Therapist": "Pediatric Occupational Therapist",
    "Pediatric Oncology Clinical Nurse Specialist": "Pediatric Oncology Clinical Nurse Specialist",
    "Pediatric Oncology Registered Nurse": "Pediatric Oncology Registered Nurse",
    "Pediatric Ophthalmology and Strabismus Specialist Physician":
        "Pediatric Ophthalmology and Strabismus Specialist Physician",
    "Pediatric Optometrist": "Pediatric Optometrist",
    "Pediatric Orthopaedic Surgery Physician": "Pediatric Orthopaedic Surgery Physician",
    "Pediatric Otolaryngology Physician": "Pediatric Otolaryngology Physician",
    "Pediatric Pathology Physician": "Pediatric Pathology Physician",
    "Pediatric Pharmacist": "Pediatric Pharmacist",
    "Pediatric Physical Therapist": "Pediatric Physical Therapist",
    "Pediatric Pulmonology Physician": "Pediatric Pulmonology Physician",
    "Pediatric Radiology Physician": "Pediatric Radiology Physician",
    "Pediatric Registered Nurse": "Pediatric Registered Nurse",
    "Pediatric Rehabilitation Medicine Physician": "Pediatric Rehabilitation Medicine Physician",
    "Pediatric Rheumatology Physician": "Pediatric Rheumatology Physician",
    "Pediatric Sleep Medicine Physician": "Pediatric Sleep Medicine Physician",
    "Pediatrics Physician": "Pediatrics Physician",
    "Pediatric Sports Medicine Physician": "Pediatric Sports Medicine Physician",
    "Pediatric Surgery Physician": "Pediatric Surgery Physician",
    "Pediatric Transplant Hepatology Physician": "Pediatric Transplant Hepatology Physician",
    "Pediatric Urology Physician": "Pediatric Urology Physician",
    Pedorthist: "Pedorthist",
    "Peer Specialist": "Peer Specialist",
    Perfusionist: "Perfusionist",
    "Perinatal Clinical Nurse Specialist": "Perinatal Clinical Nurse Specialist",
    "Perinatal Nurse Practitioner": "Perinatal Nurse Practitioner",
    "Perinatal Registered Nurse": "Perinatal Registered Nurse",
    Periodontist: "Periodontist",
    "Perioperative Clinical Nurse Specialist": "Perioperative Clinical Nurse Specialist",
    "Peritoneal Dialysis Registered Nurse": "Peritoneal Dialysis Registered Nurse",
    "Personal Care Attendant": "Personal Care Attendant",
    "Personal Emergency Response Attendant": "Personal Emergency Response Attendant",
    Pharmacist: "Pharmacist",
    "Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist":
        "Pharmacist Clinician (PhC)/ Clinical Pharmacy Specialist",
    "Pharmacotherapy Pharmacist": "Pharmacotherapy Pharmacist",
    "Pharmacy Technician": "Pharmacy Technician",
    "Ph.D. Medical Genetics": "Ph.D. Medical Genetics",
    "Ph.D. Medical Genetics Physician": "Ph.D. Medical Genetics Physician",
    "Phlebology Physician": "Phlebology Physician",
    "Phlebotomy Technician": "Phlebotomy Technician",
    "Physical Medicine & Rehabilitation Physician": "Physical Medicine & Rehabilitation Physician",
    "Physical Rehabilitation Occupational Therapist": "Physical Rehabilitation Occupational Therapist",
    "Physical Therapist": "Physical Therapist",
    "Physical Therapy Assistant": "Physical Therapy Assistant",
    "Physician Assistant": "Physician Assistant",
    "Physician Office Based Coding Specialist": "Physician Office Based Coding Specialist",
    "Plastic and Reconstructive Surgery Physician": "Plastic and Reconstructive Surgery Physician",
    "Plastic Surgery Physician": "Plastic Surgery Physician",
    "Plastic Surgery Registered Nurse": "Plastic Surgery Registered Nurse",
    "Plastic Surgery Within the Head and Neck (Plastic Surgery) Physician":
        "Plastic Surgery Within the Head and Neck (Plastic Surgery) Physician",
    "Plastic Surgery within the Head & Neck (Otolaryngology) Physician":
        "Plastic Surgery within the Head & Neck (Otolaryngology) Physician",
    "Podiatric Assistant": "Podiatric Assistant",
    Podiatrist: "Podiatrist",
    "Poetry Therapist": "Poetry Therapist",
    "Prescribing (Medical) Psychologist": "Prescribing (Medical) Psychologist",
    "Prevention Professional": "Prevention Professional",
    "Preventive Medicine/Occupational Environmental Medicine Physician":
        "Preventive Medicine/Occupational Environmental Medicine Physician",
    "Primary Care Nurse Practitioner": "Primary Care Nurse Practitioner",
    "Primary Podiatric Medicine Podiatrist": "Primary Podiatric Medicine Podiatrist",
    "Procedural Dermatology Physician": "Procedural Dermatology Physician",
    "Professional Counselor": "Professional Counselor",
    "Prosthetics Case Management": "Prosthetics Case Management",
    Prosthetist: "Prosthetist",
    Prosthodontist: "Prosthodontist",
    "Psychiatric/Mental Health Clinical Nurse Specialist": "Psychiatric/Mental Health Clinical Nurse Specialist",
    "Psychiatric/Mental Health Nurse Practitioner": "Psychiatric/Mental Health Nurse Practitioner",
    "Psychiatric/Mental Health Registered Nurse": "Psychiatric/Mental Health Registered Nurse",
    "Psychiatric Pharmacist": "Psychiatric Pharmacist",
    "Psychiatry Physician": "Psychiatry Physician",
    "Psychoanalysis Psychologist": "Psychoanalysis Psychologist",
    Psychoanalyst: "Psychoanalyst",
    Psychologist: "Psychologist",
    "Psychosomatic Medicine Physician": "Psychosomatic Medicine Physician",
    "Public Health Dentist": "Public Health Dentist",
    "Public Health & General Preventive Medicine Physician": "Public Health & General Preventive Medicine Physician",
    "Public Medicine Podiatrist": "Public Medicine Podiatrist",
    "Pulmonary Diagnostics Certified Respiratory Therapist": "Pulmonary Diagnostics Certified Respiratory Therapist",
    "Pulmonary Diagnostics Registered Respiratory Therapist": "Pulmonary Diagnostics Registered Respiratory Therapist",
    "Pulmonary Disease Physician": "Pulmonary Disease Physician",
    "Pulmonary Function Technologist": "Pulmonary Function Technologist",
    "Pulmonary Function Technologist Certified Respiratory Therapist":
        "Pulmonary Function Technologist Certified Respiratory Therapist",
    "Pulmonary Function Technologist Registered Respiratory Therapist":
        "Pulmonary Function Technologist Registered Respiratory Therapist",
    "Pulmonary Rehabilitation Certified Respiratory Therapist":
        "Pulmonary Rehabilitation Certified Respiratory Therapist",
    "Pulmonary Rehabilitation Registered Respiratory Therapist":
        "Pulmonary Rehabilitation Registered Respiratory Therapist",
    "Quality Management Radiologic Technologist": "Quality Management Radiologic Technologist",
    "Radiation Oncology Physician": "Radiation Oncology Physician",
    "Radiation Therapy Radiologic Technologist": "Radiation Therapy Radiologic Technologist",
    "Radiography Radiologic Technologist": "Radiography Radiologic Technologist",
    "Radiological Physics Physician": "Radiological Physics Physician",
    "Radiologic Technologist": "Radiologic Technologist",
    "Radiology Chiropractor": "Radiology Chiropractor",
    "Radiology Podiatrist": "Radiology Podiatrist",
    "Radiology Practitioner Assistant": "Radiology Practitioner Assistant",
    "Recreational Therapist Assistant": "Recreational Therapist Assistant",
    "Recreation Therapist": "Recreation Therapist",
    Reflexologist: "Reflexologist",
    "Registered Dietetic Technician": "Registered Dietetic Technician",
    "Registered Dietitian": "Registered Dietitian",
    "Registered Nurse": "Registered Nurse",
    "Registered Nurse First Assistant": "Registered Nurse First Assistant",
    "Registered Record Administrator": "Registered Record Administrator",
    "Registered Respiratory Therapist": "Registered Respiratory Therapist",
    "Rehabilitation Chiropractor": "Rehabilitation Chiropractor",
    "Rehabilitation Clinical Nurse Specialist": "Rehabilitation Clinical Nurse Specialist",
    "Rehabilitation Counselor": "Rehabilitation Counselor",
    "Rehabilitation Practitioner": "Rehabilitation Practitioner",
    "Rehabilitation Psychologist": "Rehabilitation Psychologist",
    "Rehabilitation Registered Nurse": "Rehabilitation Registered Nurse",
    "Religious Nonmedical Nursing Personnel": "Religious Nonmedical Nursing Personnel",
    "Religious Nonmedical Practitioner": "Religious Nonmedical Practitioner",
    "Renal Dialysis Technician": "Renal Dialysis Technician",
    "Renal Nutrition Registered Dietitian": "Renal Nutrition Registered Dietitian",
    "Reproductive Endocrinology/Infertility Registered Nurse":
        "Reproductive Endocrinology/Infertility Registered Nurse",
    "Reproductive Endocrinology Physician": "Reproductive Endocrinology Physician",
    "Research Study Abstracter/Coder": "Research Study Abstracter/Coder",
    "Research Study Specialist": "Research Study Specialist",
    "Respiratory/Developmental/Rehabilitative Specialist/Technologist":
        "Respiratory/Developmental/Rehabilitative Specialist/Technologist",
    "Retina Specialist (Ophthalmology) Physician": "Retina Specialist (Ophthalmology) Physician",
    "Rheumatology Physician": "Rheumatology Physician",
    "School Clinical Nurse Specialist": "School Clinical Nurse Specialist",
    "School Counselor": "School Counselor",
    "School Nurse Practitioner": "School Nurse Practitioner",
    "School Psychologist": "School Psychologist",
    "School Registered Nurse": "School Registered Nurse",
    "School Social Worker": "School Social Worker",
    "Single Specialty Group": "Single Specialty Group",
    "Sleep Medicine (Family Medicine) Physician": "Sleep Medicine (Family Medicine) Physician",
    "Sleep Medicine (Internal Medicine) Physician": "Sleep Medicine (Internal Medicine) Physician",
    "Sleep Medicine (Otolaryngology) Physician": "Sleep Medicine (Otolaryngology) Physician",
    "Sleep Medicine (Psychiatry & Neurology) Physician": "Sleep Medicine (Psychiatry & Neurology) Physician",
    "Sleep Specialist (PhD)": "Sleep Specialist (PhD)",
    "SNF/Subacute Care Certified Respiratory Therapist": "SNF/Subacute Care Certified Respiratory Therapist",
    "SNF/Subacute Care Registered Respiratory Therapist": "SNF/Subacute Care Registered Respiratory Therapist",
    "Social Worker": "Social Worker",
    "Solid Organ Transplant Pharmacist": "Solid Organ Transplant Pharmacist",
    "Sonography Radiologic Technologist": "Sonography Radiologic Technologist",
    "Sonography Specialist/Technologist": "Sonography Specialist/Technologist",
    Specialist: "Specialist",
    "Speech-Language Assistant": "Speech-Language Assistant",
    "Speech/Language/Hearing Specialist/Technologist": "Speech/Language/Hearing Specialist/Technologist",
    "Speech-Language Pathologist": "Speech-Language Pathologist",
    "Spinal Cord Injury Medicine Physician": "Spinal Cord Injury Medicine Physician",
    "Sports Dietetics Nutrition Registered Dietitian": "Sports Dietetics Nutrition Registered Dietitian",
    "Sports Medicine (Emergency Medicine) Physician": "Sports Medicine (Emergency Medicine) Physician",
    "Sports Medicine (Family Medicine) Physician": "Sports Medicine (Family Medicine) Physician",
    "Sports Medicine (Internal Medicine) Physician": "Sports Medicine (Internal Medicine) Physician",
    "Sports Medicine (Neuromusculoskeletal Medicine) Physician":
        "Sports Medicine (Neuromusculoskeletal Medicine) Physician",
    "Sports Medicine (Orthopaedic Surgery) Physician": "Sports Medicine (Orthopaedic Surgery) Physician",
    "Sports Medicine (Physical Medicine & Rehabilitation) Physician":
        "Sports Medicine (Physical Medicine & Rehabilitation) Physician",
    "Sports Medicine Podiatrist": "Sports Medicine Podiatrist",
    "Sports Medicine (Preventive Medicine) Physician": "Sports Medicine (Preventive Medicine) Physician",
    "Sports Medicine (Psychiatry & Neurology) Physician": "Sports Medicine (Psychiatry & Neurology) Physician",
    "Sports Physical Therapist": "Sports Physical Therapist",
    "Sports Physician Chiropractor": "Sports Physician Chiropractor",
    "Sports Vision Optometrist": "Sports Vision Optometrist",
    "Student in an Organized Health Care Education/Training Program":
        "Student in an Organized Health Care Education/Training Program",
    "Surgery of the Hand (Plastic Surgery) Physician": "Surgery of the Hand (Plastic Surgery) Physician",
    "Surgery of the Hand (Surgery) Physician": "Surgery of the Hand (Surgery) Physician",
    "Surgery Physician": "Surgery Physician",
    "Surgical Assistant": "Surgical Assistant",
    "Surgical Critical Care Physician": "Surgical Critical Care Physician",
    "Surgical Oncology Physician": "Surgical Oncology Physician",
    "Surgical Physician Assistant": "Surgical Physician Assistant",
    "Surgical Technologist": "Surgical Technologist",
    Technician: "Technician",
    "Technician/Technologist": "Technician/Technologist",
    "Therapeutic Radiology Physician": "Therapeutic Radiology Physician",
    "Thermography Chiropractor": "Thermography Chiropractor",
    "Thoracic Surgery (Cardiothoracic Vascular Surgery) Physician":
        "Thoracic Surgery (Cardiothoracic Vascular Surgery) Physician",
    "Transplantation Clinical Nurse Specialist": "Transplantation Clinical Nurse Specialist",
    "Transplant Hepatology Physician": "Transplant Hepatology Physician",
    "Transplant Surgery Physician": "Transplant Surgery Physician",
    "Trauma Surgery Physician": "Trauma Surgery Physician",
    "Undersea and Hyperbaric Medicine (Emergency Medicine) Physician":
        "Undersea and Hyperbaric Medicine (Emergency Medicine) Physician",
    "Undersea and Hyperbaric Medicine (Preventive Medicine) Physician":
        "Undersea and Hyperbaric Medicine (Preventive Medicine) Physician",
    "Urology Physician": "Urology Physician",
    "Urology Registered Nurse": "Urology Registered Nurse",
    "Uveitis and Ocular Inflammatory Disease (Ophthalmology) Physician":
        "Uveitis and Ocular Inflammatory Disease (Ophthalmology) Physician",
    "Vascular & Interventional Radiology Physician": "Vascular & Interventional Radiology Physician",
    "Vascular-Interventional Technology Radiologic Technologist":
        "Vascular-Interventional Technology Radiologic Technologist",
    "Vascular Neurology Physician": "Vascular Neurology Physician",
    "Vascular Sonography Radiologic Technologist": "Vascular Sonography Radiologic Technologist",
    "Vascular Specialist/Technologist": "Vascular Specialist/Technologist",
    "Vascular Surgery Physician": "Vascular Surgery Physician",
    "Vehicle Modifications Contractor": "Vehicle Modifications Contractor",
    Veterinarian: "Veterinarian",
    "Veterinary Technician": "Veterinary Technician",
    "Vision Therapy Optometrist": "Vision Therapy Optometrist",
    "Women's Health Clinical Nurse Specialist": "Women's Health Clinical Nurse Specialist",
    "Women's Health Nurse Practitioner": "Women's Health Nurse Practitioner",
    "Wound Care Registered Nurse": "Wound Care Registered Nurse",
};

export const rocketReachLocationMap: { [key: string]: string } = {
    "Arizona, US": "Arizona, US",
    "Alabama, US": "Alabama, US",
    "Alaska, US": "Alaska, US",
    "Arkansas, US": "Arkansas, US",
    "California, US": "California, US",
    "Colorado, US": "Colorado, US",
    "Connecticut, US": "Connecticut, US",
    "Delaware, US": "Delaware, US",
    "Florida, US": "Florida, US",
    "Georgia, US": "Georgia, US",
    "Hawaii, US": "Hawaii, US",
    "Idaho, US": "Idaho, US",
    "Illinois, US": "Illinois, US",
    "Indiana, US": "Indiana, US",
    "Iowa, US": "Iowa, US",
    "Kansas, US": "Kansas, US",
    "Kentucky, US": "Kentucky, US",
    "Louisiana, US": "Louisiana, US",
    "Maine, US": "Maine, US",
    "Maryland, US": "Maryland, US",
    "Massachusetts, US": "Massachusetts, US",
    "Michigan, US": "Michigan, US",
    "Minnesota, US": "Minnesota, US",
    "Mississippi, US": "Mississippi, US",
    "Missouri, US": "Missouri, US",
    "Montana, US": "Montana, US",
    "Nebraska, US": "Nebraska, US",
    "Nevada, US": "Nevada, US",
    "New Hampshire, US": "New Hampshire, US",
    "New Jersey, US": "New Jersey, US",
    "New Mexico, US": "New Mexico, US",
    "New York, US": "New York, US",
    "North Carolina, US": "North Carolina, US",
    "North Dakota, US": "North Dakota, US",
    "Ohio, US": "Ohio, US",
    "Oklahoma, US": "Oklahoma, US",
    "Oregon, US": "Oregon, US",
    "Pennsylvania, US": "Pennsylvania, US",
    "Rhode Island, US": "Rhode Island, US",
    "South Carolina, US": "South Carolina, US",
    "South Dakota, US": "South Dakota, US",
    "Tennessee, US": "Tennessee, US",
    "Texas, US": "Texas, US",
    "Utah, US": "Utah, US",
    "Vermont, US": "Vermont, US",
    "Virginia, US": "Virginia, US",
    "Washington, US": "Washington, US",
    "West Virginia, US": "West Virginia, US",
    "Wisconsin, US": "Wisconsin, US",
    "Wyoming, US": "Wyoming, US",
    "District of Columbia": "District of Columbia",
    "American Samoa": "American Samoa",
    "Canal Zone": "Canal Zone",
    Guam: "Guam",
    "Puerto Rico": "Puerto Rico",
    "Virgin Islands": "Virgin Islands",
    "Alexander City": "Alexander City",
    Andalusia: "Andalusia",
    Anniston: "Anniston",
    Athens: "Athens",
    Atmore: "Atmore",
    Auburn: "Auburn",
    Bessemer: "Bessemer",
    Birmingham: "Birmingham",
    Chickasaw: "Chickasaw",
    Clanton: "Clanton",
    Cullman: "Cullman",
    Decatur: "Decatur",
    Demopolis: "Demopolis",
    Dothan: "Dothan",
    Enterprise: "Enterprise",
    Eufaula: "Eufaula",
    "Fort Payne": "Fort Payne",
    Gadsden: "Gadsden",
    Greenville: "Greenville",
    Guntersville: "Guntersville",
    Huntsville: "Huntsville",
    Jasper: "Jasper",
    Marion: "Marion",
    Mobile: "Mobile",
    Montgomery: "Montgomery",
    Opelika: "Opelika",
    Ozark: "Ozark",
    "Phenix City": "Phenix City",
    Prichard: "Prichard",
    Scottsboro: "Scottsboro",
    Selma: "Selma",
    Sheffield: "Sheffield",
    Sylacauga: "Sylacauga",
    Talladega: "Talladega",
    Troy: "Troy",
    Tuscaloosa: "Tuscaloosa",
    Tuscumbia: "Tuscumbia",
    Tuskegee: "Tuskegee",
    Anchorage: "Anchorage",
    Cordova: "Cordova",
    Fairbanks: "Fairbanks",
    Haines: "Haines",
    Homer: "Homer",
    Juneau: "Juneau",
    Ketchikan: "Ketchikan",
    Kodiak: "Kodiak",
    Kotzebue: "Kotzebue",
    Nome: "Nome",
    Palmer: "Palmer",
    Seward: "Seward",
    Sitka: "Sitka",
    Skagway: "Skagway",
    Valdez: "Valdez",
    Ajo: "Ajo",
    Avondale: "Avondale",
    Bisbee: "Bisbee",
    "Casa Grande": "Casa Grande",
    Chandler: "Chandler",
    Clifton: "Clifton",
    Douglas: "Douglas",
    Flagstaff: "Flagstaff",
    Florence: "Florence",
    "Gila Bend": "Gila Bend",
    Glendale: "Glendale",
    Globe: "Globe",
    Kingman: "Kingman",
    "Lake Havasu City": "Lake Havasu City",
    Mesa: "Mesa",
    Nogales: "Nogales",
    Oraibi: "Oraibi",
    Phoenix: "Phoenix",
    Prescott: "Prescott",
    Scottsdale: "Scottsdale",
    "Sierra Vista": "Sierra Vista",
    Tempe: "Tempe",
    Tombstone: "Tombstone",
    Tucson: "Tucson",
    Walpi: "Walpi",
    "Window Rock": "Window Rock",
    Winslow: "Winslow",
    Yuma: "Yuma",
    Arkadelphia: "Arkadelphia",
    "Arkansas Post": "Arkansas Post",
    Batesville: "Batesville",
    Benton: "Benton",
    Blytheville: "Blytheville",
    Camden: "Camden",
    Conway: "Conway",
    Crossett: "Crossett",
    "El Dorado": "El Dorado",
    Fayetteville: "Fayetteville",
    "Forrest City": "Forrest City",
    "Fort Smith": "Fort Smith",
    Harrison: "Harrison",
    Helena: "Helena",
    Hope: "Hope",
    "Hot Springs": "Hot Springs",
    Jacksonville: "Jacksonville",
    Jonesboro: "Jonesboro",
    "Little Rock": "Little Rock",
    Magnolia: "Magnolia",
    Morrilton: "Morrilton",
    Newport: "Newport",
    "North Little Rock": "North Little Rock",
    Osceola: "Osceola",
    "Pine Bluff": "Pine Bluff",
    Rogers: "Rogers",
    Searcy: "Searcy",
    Stuttgart: "Stuttgart",
    "Van Buren": "Van Buren",
    "West Memphis": "West Memphis",
    Alameda: "Alameda",
    Alhambra: "Alhambra",
    Anaheim: "Anaheim",
    Antioch: "Antioch",
    Arcadia: "Arcadia",
    Bakersfield: "Bakersfield",
    Barstow: "Barstow",
    Belmont: "Belmont",
    Berkeley: "Berkeley",
    "Beverly Hills": "Beverly Hills",
    Brea: "Brea",
    "Buena Park": "Buena Park",
    Burbank: "Burbank",
    Calexico: "Calexico",
    Calistoga: "Calistoga",
    Carlsbad: "Carlsbad",
    Carmel: "Carmel",
    Chico: "Chico",
    "Chula Vista": "Chula Vista",
    Claremont: "Claremont",
    Compton: "Compton",
    Concord: "Concord",
    Corona: "Corona",
    Coronado: "Coronado",
    "Costa Mesa": "Costa Mesa",
    "Culver City": "Culver City",
    "Daly City": "Daly City",
    Davis: "Davis",
    Downey: "Downey",
    "El Centro": "El Centro",
    "El Cerrito": "El Cerrito",
    "El Monte": "El Monte",
    Escondido: "Escondido",
    Eureka: "Eureka",
    Fairfield: "Fairfield",
    Fontana: "Fontana",
    Fremont: "Fremont",
    Fresno: "Fresno",
    Fullerton: "Fullerton",
    "Garden Grove": "Garden Grove",
    Hayward: "Hayward",
    Hollywood: "Hollywood",
    "Huntington Beach": "Huntington Beach",
    Indio: "Indio",
    Inglewood: "Inglewood",
    Irvine: "Irvine",
    "La Habra": "La Habra",
    "Laguna Beach": "Laguna Beach",
    Lancaster: "Lancaster",
    Livermore: "Livermore",
    Lodi: "Lodi",
    Lompoc: "Lompoc",
    "Long Beach": "Long Beach",
    "Los Angeles": "Los Angeles",
    Malibu: "Malibu",
    Martinez: "Martinez",
    Marysville: "Marysville",
    "Menlo Park": "Menlo Park",
    Merced: "Merced",
    Modesto: "Modesto",
    Monterey: "Monterey",
    "Mountain View": "Mountain View",
    Napa: "Napa",
    Needles: "Needles",
    "Newport Beach": "Newport Beach",
    Norwalk: "Norwalk",
    Novato: "Novato",
    Oakland: "Oakland",
    Oceanside: "Oceanside",
    Ojai: "Ojai",
    Ontario: "Ontario",
    Orange: "Orange",
    Oroville: "Oroville",
    Oxnard: "Oxnard",
    "Pacific Grove": "Pacific Grove",
    "Palm Springs": "Palm Springs",
    Palmdale: "Palmdale",
    "Palo Alto": "Palo Alto",
    Pasadena: "Pasadena",
    Petaluma: "Petaluma",
    Pomona: "Pomona",
    "Port Hueneme": "Port Hueneme",
    "Rancho Cucamonga": "Rancho Cucamonga",
    "Red Bluff": "Red Bluff",
    Redding: "Redding",
    Redlands: "Redlands",
    "Redondo Beach": "Redondo Beach",
    "Redwood City": "Redwood City",
    Richmond: "Richmond",
    Riverside: "Riverside",
    Roseville: "Roseville",
    Sacramento: "Sacramento",
    Salinas: "Salinas",
    "San Bernardino": "San Bernardino",
    "San Clemente": "San Clemente",
    "San Diego": "San Diego",
    "San Fernando": "San Fernando",
    "San Francisco": "San Francisco",
    "San Gabriel": "San Gabriel",
    "San Jose": "San Jose",
    "San Juan Capistrano": "San Juan Capistrano",
    "San Leandro": "San Leandro",
    "San Luis Obispo": "San Luis Obispo",
    "San Marino": "San Marino",
    "San Mateo": "San Mateo",
    "San Pedro": "San Pedro",
    "San Rafael": "San Rafael",
    "San Simeon": "San Simeon",
    "Santa Ana": "Santa Ana",
    "Santa Barbara": "Santa Barbara",
    "Santa Clara": "Santa Clara",
    "Santa Clarita": "Santa Clarita",
    "Santa Cruz": "Santa Cruz",
    "Santa Monica": "Santa Monica",
    "Santa Rosa": "Santa Rosa",
    Sausalito: "Sausalito",
    "Simi Valley": "Simi Valley",
    Sonoma: "Sonoma",
    "South San Francisco": "South San Francisco",
    Stockton: "Stockton",
    Sunnyvale: "Sunnyvale",
    Susanville: "Susanville",
    "Thousand Oaks": "Thousand Oaks",
    Torrance: "Torrance",
    Turlock: "Turlock",
    Ukiah: "Ukiah",
    Vallejo: "Vallejo",
    Ventura: "Ventura",
    Victorville: "Victorville",
    Visalia: "Visalia",
    "Walnut Creek": "Walnut Creek",
    Watts: "Watts",
    "West Covina": "West Covina",
    Whittier: "Whittier",
    Woodland: "Woodland",
    "Yorba Linda": "Yorba Linda",
    "Yuba City": "Yuba City",
    Alamosa: "Alamosa",
    Aspen: "Aspen",
    Aurora: "Aurora",
    Boulder: "Boulder",
    Breckenridge: "Breckenridge",
    Brighton: "Brighton",
    "Canon City": "Canon City",
    "Central City": "Central City",
    Climax: "Climax",
    "Colorado Springs": "Colorado Springs",
    Cortez: "Cortez",
    "Cripple Creek": "Cripple Creek",
    Denver: "Denver",
    Durango: "Durango",
    Englewood: "Englewood",
    "Estes Park": "Estes Park",
    "Fort Collins": "Fort Collins",
    "Fort Morgan": "Fort Morgan",
    Georgetown: "Georgetown",
    "Glenwood Springs": "Glenwood Springs",
    Golden: "Golden",
    "Grand Junction": "Grand Junction",
    Greeley: "Greeley",
    Gunnison: "Gunnison",
    "La Junta": "La Junta",
    Leadville: "Leadville",
    Littleton: "Littleton",
    Longmont: "Longmont",
    Loveland: "Loveland",
    Montrose: "Montrose",
    Ouray: "Ouray",
    "Pagosa Springs": "Pagosa Springs",
    Pueblo: "Pueblo",
    Silverton: "Silverton",
    "Steamboat Springs": "Steamboat Springs",
    Sterling: "Sterling",
    Telluride: "Telluride",
    Trinidad: "Trinidad",
    Vail: "Vail",
    Walsenburg: "Walsenburg",
    Westminster: "Westminster",
    Ansonia: "Ansonia",
    Berlin: "Berlin",
    Bloomfield: "Bloomfield",
    Branford: "Branford",
    Bridgeport: "Bridgeport",
    Bristol: "Bristol",
    Coventry: "Coventry",
    Danbury: "Danbury",
    Darien: "Darien",
    Derby: "Derby",
    "East Hartford": "East Hartford",
    "East Haven": "East Haven",
    Enfield: "Enfield",
    Farmington: "Farmington",
    Greenwich: "Greenwich",
    Groton: "Groton",
    Guilford: "Guilford",
    Hamden: "Hamden",
    Hartford: "Hartford",
    Lebanon: "Lebanon",
    Litchfield: "Litchfield",
    Manchester: "Manchester",
    Mansfield: "Mansfield",
    Meriden: "Meriden",
    Middletown: "Middletown",
    Milford: "Milford",
    Mystic: "Mystic",
    Naugatuck: "Naugatuck",
    "New Britain": "New Britain",
    "New Haven": "New Haven",
    "New London": "New London",
    "North Haven": "North Haven",
    Norwich: "Norwich",
    "Old Saybrook": "Old Saybrook",
    Seymour: "Seymour",
    Shelton: "Shelton",
    Simsbury: "Simsbury",
    Southington: "Southington",
    Stamford: "Stamford",
    Stonington: "Stonington",
    Stratford: "Stratford",
    Torrington: "Torrington",
    Wallingford: "Wallingford",
    Waterbury: "Waterbury",
    Waterford: "Waterford",
    Watertown: "Watertown",
    "West Hartford": "West Hartford",
    "West Haven": "West Haven",
    Westport: "Westport",
    Wethersfield: "Wethersfield",
    Willimantic: "Willimantic",
    Windham: "Windham",
    Windsor: "Windsor",
    "Windsor Locks": "Windsor Locks",
    Winsted: "Winsted",
    Dover: "Dover",
    Lewes: "Lewes",
    "New Castle": "New Castle",
    Newark: "Newark",
    Smyrna: "Smyrna",
    Wilmington: "Wilmington",
    Apalachicola: "Apalachicola",
    Bartow: "Bartow",
    "Belle Glade": "Belle Glade",
    "Boca Raton": "Boca Raton",
    Bradenton: "Bradenton",
    "Cape Coral": "Cape Coral",
    Clearwater: "Clearwater",
    "Cocoa Beach": "Cocoa Beach",
    "Cocoa-Rockledge": "Cocoa-Rockledge",
    "Coral Gables": "Coral Gables",
    "Daytona Beach": "Daytona Beach",
    "De Land": "De Land",
    "Deerfield Beach": "Deerfield Beach",
    "Delray Beach": "Delray Beach",
    "Fernandina Beach": "Fernandina Beach",
    "Fort Lauderdale": "Fort Lauderdale",
    "Fort Myers": "Fort Myers",
    "Fort Pierce": "Fort Pierce",
    "Fort Walton Beach": "Fort Walton Beach",
    Gainesville: "Gainesville",
    "Hallandale Beach": "Hallandale Beach",
    Hialeah: "Hialeah",
    Homestead: "Homestead",
    "Key West": "Key West",
    "Lake City": "Lake City",
    "Lake Wales": "Lake Wales",
    Lakeland: "Lakeland",
    Largo: "Largo",
    Melbourne: "Melbourne",
    Miami: "Miami",
    "Miami Beach": "Miami Beach",
    Naples: "Naples",
    "New Smyrna Beach": "New Smyrna Beach",
    Ocala: "Ocala",
    Orlando: "Orlando",
    "Ormond Beach": "Ormond Beach",
    Palatka: "Palatka",
    "Palm Bay": "Palm Bay",
    "Palm Beach": "Palm Beach",
    "Panama City": "Panama City",
    Pensacola: "Pensacola",
    "Pompano Beach": "Pompano Beach",
    "Saint Augustine": "Saint Augustine",
    "Saint Petersburg": "Saint Petersburg",
    Sanford: "Sanford",
    Sarasota: "Sarasota",
    Sebring: "Sebring",
    Tallahassee: "Tallahassee",
    Tampa: "Tampa",
    "Tarpon Springs": "Tarpon Springs",
    Titusville: "Titusville",
    Venice: "Venice",
    "West Palm Beach": "West Palm Beach",
    "White Springs": "White Springs",
    "Winter Haven": "Winter Haven",
    "Winter Park": "Winter Park",
    Albany: "Albany",
    Americus: "Americus",
    Andersonville: "Andersonville",
    Atlanta: "Atlanta",
    Augusta: "Augusta",
    Bainbridge: "Bainbridge",
    Blairsville: "Blairsville",
    Brunswick: "Brunswick",
    Calhoun: "Calhoun",
    Carrollton: "Carrollton",
    Columbus: "Columbus",
    Dahlonega: "Dahlonega",
    Dalton: "Dalton",
    "East Point": "East Point",
    Fitzgerald: "Fitzgerald",
    "Fort Valley": "Fort Valley",
    "La Grange": "La Grange",
    Macon: "Macon",
    Marietta: "Marietta",
    Milledgeville: "Milledgeville",
    Plains: "Plains",
    Rome: "Rome",
    Savannah: "Savannah",
    Toccoa: "Toccoa",
    Valdosta: "Valdosta",
    "Warm Springs": "Warm Springs",
    "Warner Robins": "Warner Robins",
    Washington: "Washington",
    Waycross: "Waycross",
    Hanalei: "Hanalei",
    Hilo: "Hilo",
    Honaunau: "Honaunau",
    Honolulu: "Honolulu",
    Kahului: "Kahului",
    Kaneohe: "Kaneohe",
    Kapaa: "Kapaa",
    Kawaihae: "Kawaihae",
    Lahaina: "Lahaina",
    Laie: "Laie",
    Wahiawa: "Wahiawa",
    Wailuku: "Wailuku",
    Waimea: "Waimea",
    Blackfoot: "Blackfoot",
    Boise: "Boise",
    "Bonners Ferry": "Bonners Ferry",
    Caldwell: "Caldwell",
    "Coeur dAlene": "Coeur dAlene",
    "Idaho City": "Idaho City",
    "Idaho Falls": "Idaho Falls",
    Kellogg: "Kellogg",
    Lewiston: "Lewiston",
    Moscow: "Moscow",
    Nampa: "Nampa",
    Pocatello: "Pocatello",
    "Priest River": "Priest River",
    Rexburg: "Rexburg",
    "Sun Valley": "Sun Valley",
    "Twin Falls": "Twin Falls",
    Alton: "Alton",
    "Arlington Heights": "Arlington Heights",
    Arthur: "Arthur",
    Belleville: "Belleville",
    Belvidere: "Belvidere",
    Bloomington: "Bloomington",
    Brookfield: "Brookfield",
    Cahokia: "Cahokia",
    Cairo: "Cairo",
    "Calumet City": "Calumet City",
    Canton: "Canton",
    Carbondale: "Carbondale",
    Carlinville: "Carlinville",
    Carthage: "Carthage",
    Centralia: "Centralia",
    Champaign: "Champaign",
    Charleston: "Charleston",
    Chester: "Chester",
    Chicago: "Chicago",
    "Chicago Heights": "Chicago Heights",
    Cicero: "Cicero",
    Collinsville: "Collinsville",
    Danville: "Danville",
    DeKalb: "DeKalb",
    "Des Plaines": "Des Plaines",
    Dixon: "Dixon",
    "East Moline": "East Moline",
    "East Saint Louis": "East Saint Louis",
    Effingham: "Effingham",
    Elgin: "Elgin",
    Elmhurst: "Elmhurst",
    Evanston: "Evanston",
    Freeport: "Freeport",
    Galena: "Galena",
    Galesburg: "Galesburg",
    "Glen Ellyn": "Glen Ellyn",
    Glenview: "Glenview",
    "Granite City": "Granite City",
    Harrisburg: "Harrisburg",
    Herrin: "Herrin",
    "Highland Park": "Highland Park",
    Joliet: "Joliet",
    Kankakee: "Kankakee",
    Kaskaskia: "Kaskaskia",
    Kewanee: "Kewanee",
    "La Salle": "La Salle",
    "Lake Forest": "Lake Forest",
    Libertyville: "Libertyville",
    Lincoln: "Lincoln",
    Lisle: "Lisle",
    Lombard: "Lombard",
    Macomb: "Macomb",
    Mattoon: "Mattoon",
    Moline: "Moline",
    Monmouth: "Monmouth",
    "Mount Vernon": "Mount Vernon",
    Mundelein: "Mundelein",
    Naperville: "Naperville",
    Nauvoo: "Nauvoo",
    Normal: "Normal",
    "North Chicago": "North Chicago",
    "Oak Park": "Oak Park",
    Oregon: "Oregon",
    Ottawa: "Ottawa",
    Palatine: "Palatine",
    "Park Forest": "Park Forest",
    "Park Ridge": "Park Ridge",
    Pekin: "Pekin",
    Peoria: "Peoria",
    Petersburg: "Petersburg",
    Pontiac: "Pontiac",
    Quincy: "Quincy",
    Rantoul: "Rantoul",
    "River Forest": "River Forest",
    "Rock Island": "Rock Island",
    Rockford: "Rockford",
    Salem: "Salem",
    Shawneetown: "Shawneetown",
    Skokie: "Skokie",
    "South Holland": "South Holland",
    Springfield: "Springfield",
    Streator: "Streator",
    Summit: "Summit",
    Urbana: "Urbana",
    Vandalia: "Vandalia",
    Virden: "Virden",
    Waukegan: "Waukegan",
    Wheaton: "Wheaton",
    Wilmette: "Wilmette",
    Winnetka: "Winnetka",
    "Wood River": "Wood River",
    Zion: "Zion",
    Anderson: "Anderson",
    Bedford: "Bedford",
    Connersville: "Connersville",
    Corydon: "Corydon",
    Crawfordsville: "Crawfordsville",
    "East Chicago": "East Chicago",
    Elkhart: "Elkhart",
    Elwood: "Elwood",
    Evansville: "Evansville",
    "Fort Wayne": "Fort Wayne",
    "French Lick": "French Lick",
    Gary: "Gary",
    Geneva: "Geneva",
    Goshen: "Goshen",
    Greenfield: "Greenfield",
    Hammond: "Hammond",
    Hobart: "Hobart",
    Huntington: "Huntington",
    Indianapolis: "Indianapolis",
    Jeffersonville: "Jeffersonville",
    Kokomo: "Kokomo",
    Lafayette: "Lafayette",
    Madison: "Madison",
    "Michigan City": "Michigan City",
    Mishawaka: "Mishawaka",
    Muncie: "Muncie",
    Nappanee: "Nappanee",
    Nashville: "Nashville",
    "New Albany": "New Albany",
    "New Harmony": "New Harmony",
    Peru: "Peru",
    Plymouth: "Plymouth",
    "Santa Claus": "Santa Claus",
    Shelbyville: "Shelbyville",
    "South Bend": "South Bend",
    "Terre Haute": "Terre Haute",
    Valparaiso: "Valparaiso",
    Vincennes: "Vincennes",
    Wabash: "Wabash",
    "West Lafayette": "West Lafayette",
    "Amana Colonies": "Amana Colonies",
    Ames: "Ames",
    Boone: "Boone",
    Burlington: "Burlington",
    "Cedar Falls": "Cedar Falls",
    "Cedar Rapids": "Cedar Rapids",
    "Charles City": "Charles City",
    Cherokee: "Cherokee",
    Clinton: "Clinton",
    "Council Bluffs": "Council Bluffs",
    Davenport: "Davenport",
    "Des Moines": "Des Moines",
    Dubuque: "Dubuque",
    Estherville: "Estherville",
    "Fort Dodge": "Fort Dodge",
    Grinnell: "Grinnell",
    Indianola: "Indianola",
    "Iowa City": "Iowa City",
    Keokuk: "Keokuk",
    "Mason City": "Mason City",
    "Mount Pleasant": "Mount Pleasant",
    Muscatine: "Muscatine",
    Newton: "Newton",
    Oskaloosa: "Oskaloosa",
    Ottumwa: "Ottumwa",
    "Sioux City": "Sioux City",
    Waterloo: "Waterloo",
    "Webster City": "Webster City",
    "West Des Moines": "West Des Moines",
    Abilene: "Abilene",
    "Arkansas City": "Arkansas City",
    Atchison: "Atchison",
    Chanute: "Chanute",
    Coffeyville: "Coffeyville",
    "Council Grove": "Council Grove",
    "Dodge City": "Dodge City",
    Emporia: "Emporia",
    "Fort Scott": "Fort Scott",
    "Garden City": "Garden City",
    "Great Bend": "Great Bend",
    Hays: "Hays",
    Hutchinson: "Hutchinson",
    Independence: "Independence",
    "Junction City": "Junction City",
    "Kansas City": "Kansas City",
    Lawrence: "Lawrence",
    Leavenworth: "Leavenworth",
    Liberal: "Liberal",
    Manhattan: "Manhattan",
    McPherson: "McPherson",
    "Medicine Lodge": "Medicine Lodge",
    Olathe: "Olathe",
    Osawatomie: "Osawatomie",
    "Overland Park": "Overland Park",
    Pittsburg: "Pittsburg",
    Salina: "Salina",
    Shawnee: "Shawnee",
    "Smith Center": "Smith Center",
    Topeka: "Topeka",
    Wichita: "Wichita",
    Ashland: "Ashland",
    Barbourville: "Barbourville",
    Bardstown: "Bardstown",
    Berea: "Berea",
    Boonesborough: "Boonesborough",
    "Bowling Green": "Bowling Green",
    Campbellsville: "Campbellsville",
    Covington: "Covington",
    Elizabethtown: "Elizabethtown",
    Frankfort: "Frankfort",
    Harlan: "Harlan",
    Harrodsburg: "Harrodsburg",
    Hazard: "Hazard",
    Henderson: "Henderson",
    Hodgenville: "Hodgenville",
    Hopkinsville: "Hopkinsville",
    Lexington: "Lexington",
    Louisville: "Louisville",
    Mayfield: "Mayfield",
    Maysville: "Maysville",
    Middlesboro: "Middlesboro",
    Owensboro: "Owensboro",
    Paducah: "Paducah",
    Paris: "Paris",
    Abbeville: "Abbeville",
    Alexandria: "Alexandria",
    Bastrop: "Bastrop",
    "Baton Rouge": "Baton Rouge",
    Bogalusa: "Bogalusa",
    "Bossier City": "Bossier City",
    Gretna: "Gretna",
    Houma: "Houma",
    "Lake Charles": "Lake Charles",
    Monroe: "Monroe",
    "Morgan City": "Morgan City",
    Natchitoches: "Natchitoches",
    "New Iberia": "New Iberia",
    "New Orleans": "New Orleans",
    Opelousas: "Opelousas",
    Ruston: "Ruston",
    "Saint Martinville": "Saint Martinville",
    Shreveport: "Shreveport",
    Thibodaux: "Thibodaux",
    Aberdeen: "Aberdeen",
    Annapolis: "Annapolis",
    Baltimore: "Baltimore",
    "Bethesda-Chevy Chase": "Bethesda-Chevy Chase",
    Bowie: "Bowie",
    Cambridge: "Cambridge",
    Catonsville: "Catonsville",
    "College Park": "College Park",
    Columbia: "Columbia",
    Cumberland: "Cumberland",
    Easton: "Easton",
    Elkton: "Elkton",
    Emmitsburg: "Emmitsburg",
    Frederick: "Frederick",
    Greenbelt: "Greenbelt",
    Hagerstown: "Hagerstown",
    Hyattsville: "Hyattsville",
    Laurel: "Laurel",
    "Ocean City": "Ocean City",
    Rockville: "Rockville",
    "Saint Marys City": "Saint Marys City",
    Salisbury: "Salisbury",
    "Silver Spring": "Silver Spring",
    "Takoma Park": "Takoma Park",
    Towson: "Towson",
    Bangor: "Bangor",
    "Bar Harbor": "Bar Harbor",
    Bath: "Bath",
    Belfast: "Belfast",
    Biddeford: "Biddeford",
    "Boothbay Harbor": "Boothbay Harbor",
    Calais: "Calais",
    Caribou: "Caribou",
    Castine: "Castine",
    Eastport: "Eastport",
    Ellsworth: "Ellsworth",
    "Fort Kent": "Fort Kent",
    Gardiner: "Gardiner",
    Houlton: "Houlton",
    Kennebunkport: "Kennebunkport",
    Kittery: "Kittery",
    Lubec: "Lubec",
    Machias: "Machias",
    Orono: "Orono",
    Portland: "Portland",
    "Presque Isle": "Presque Isle",
    Rockland: "Rockland",
    Rumford: "Rumford",
    Saco: "Saco",
    Scarborough: "Scarborough",
    Waterville: "Waterville",
    York: "York",
    Abington: "Abington",
    Adams: "Adams",
    Amesbury: "Amesbury",
    Amherst: "Amherst",
    Andover: "Andover",
    Arlington: "Arlington",
    Athol: "Athol",
    Attleboro: "Attleboro",
    Barnstable: "Barnstable",
    Beverly: "Beverly",
    Boston: "Boston",
    Bourne: "Bourne",
    Braintree: "Braintree",
    Brockton: "Brockton",
    Brookline: "Brookline",
    Charlestown: "Charlestown",
    Chelmsford: "Chelmsford",
    Chelsea: "Chelsea",
    Chicopee: "Chicopee",
    Cohasset: "Cohasset",
    Danvers: "Danvers",
    Dartmouth: "Dartmouth",
    Dedham: "Dedham",
    Dennis: "Dennis",
    Duxbury: "Duxbury",
    Eastham: "Eastham",
    Edgartown: "Edgartown",
    Everett: "Everett",
    Fairhaven: "Fairhaven",
    "Fall River": "Fall River",
    Falmouth: "Falmouth",
    Fitchburg: "Fitchburg",
    Framingham: "Framingham",
    Gloucester: "Gloucester",
    "Great Barrington": "Great Barrington",
    Harwich: "Harwich",
    Haverhill: "Haverhill",
    Hingham: "Hingham",
    Holyoke: "Holyoke",
    Hyannis: "Hyannis",
    Ipswich: "Ipswich",
    Lenox: "Lenox",
    Leominster: "Leominster",
    Lowell: "Lowell",
    Ludlow: "Ludlow",
    Lynn: "Lynn",
    Malden: "Malden",
    Marblehead: "Marblehead",
    Marlborough: "Marlborough",
    Medford: "Medford",
    Milton: "Milton",
    Nahant: "Nahant",
    Natick: "Natick",
    "New Bedford": "New Bedford",
    Newburyport: "Newburyport",
    "North Adams": "North Adams",
    Northampton: "Northampton",
    Norton: "Norton",
    Norwood: "Norwood",
    Peabody: "Peabody",
    Pittsfield: "Pittsfield",
    Provincetown: "Provincetown",
    Randolph: "Randolph",
    Revere: "Revere",
    Sandwich: "Sandwich",
    Saugus: "Saugus",
    Somerville: "Somerville",
    "South Hadley": "South Hadley",
    Stockbridge: "Stockbridge",
    Stoughton: "Stoughton",
    Sturbridge: "Sturbridge",
    Sudbury: "Sudbury",
    Taunton: "Taunton",
    Tewksbury: "Tewksbury",
    Truro: "Truro",
    Webster: "Webster",
    Wellesley: "Wellesley",
    Wellfleet: "Wellfleet",
    "West Bridgewater": "West Bridgewater",
    "West Springfield": "West Springfield",
    Westfield: "Westfield",
    Weymouth: "Weymouth",
    Whitman: "Whitman",
    Williamstown: "Williamstown",
    Woburn: "Woburn",
    "Woods Hole": "Woods Hole",
    Worcester: "Worcester",

    Adrian: "Adrian",
    Alma: "Alma",
    "Ann Arbor": "Ann Arbor",
    "Battle Creek": "Battle Creek",
    "Bay City": "Bay City",
    "Benton Harbor": "Benton Harbor",
    "Bloomfield Hills": "Bloomfield Hills",
    Cadillac: "Cadillac",
    Charlevoix: "Charlevoix",
    Cheboygan: "Cheboygan",
    Dearborn: "Dearborn",
    Detroit: "Detroit",
    "East Lansing": "East Lansing",
    Eastpointe: "Eastpointe",
    Ecorse: "Ecorse",
    Escanaba: "Escanaba",
    Flint: "Flint",
    "Grand Haven": "Grand Haven",
    "Grand Rapids": "Grand Rapids",
    Grayling: "Grayling",
    "Grosse Pointe": "Grosse Pointe",
    Hancock: "Hancock",
    Holland: "Holland",
    Houghton: "Houghton",
    Interlochen: "Interlochen",
    "Iron Mountain": "Iron Mountain",
    Ironwood: "Ironwood",
    Ishpeming: "Ishpeming",
    Jackson: "Jackson",
    Kalamazoo: "Kalamazoo",
    Lansing: "Lansing",
    Livonia: "Livonia",
    Ludington: "Ludington",
    "Mackinaw City": "Mackinaw City",
    Manistee: "Manistee",
    Marquette: "Marquette",
    Menominee: "Menominee",
    Midland: "Midland",
    Muskegon: "Muskegon",
    Niles: "Niles",
    Petoskey: "Petoskey",
    "Port Huron": "Port Huron",
    "Royal Oak": "Royal Oak",
    Saginaw: "Saginaw",
    "Saint Ignace": "Saint Ignace",
    "Saint Joseph": "Saint Joseph",
    "Sault Sainte Marie": "Sault Sainte Marie",
    "Traverse City": "Traverse City",
    Trenton: "Trenton",
    Warren: "Warren",
    Wyandotte: "Wyandotte",
    Ypsilanti: "Ypsilanti",
    "Albert Lea": "Albert Lea",
    Austin: "Austin",
    Bemidji: "Bemidji",
    Brainerd: "Brainerd",
    Crookston: "Crookston",
    Duluth: "Duluth",
    Ely: "Ely",
    Eveleth: "Eveleth",
    Faribault: "Faribault",
    "Fergus Falls": "Fergus Falls",
    Hastings: "Hastings",
    Hibbing: "Hibbing",
    "International Falls": "International Falls",
    "Little Falls": "Little Falls",
    Mankato: "Mankato",
    Minneapolis: "Minneapolis",
    Moorhead: "Moorhead",
    "New Ulm": "New Ulm",
    Northfield: "Northfield",
    Owatonna: "Owatonna",
    Pipestone: "Pipestone",
    "Red Wing": "Red Wing",
    Rochester: "Rochester",
    "Saint Cloud": "Saint Cloud",
    "Saint Paul": "Saint Paul",
    "Sauk Centre": "Sauk Centre",
    "South Saint Paul": "South Saint Paul",
    Stillwater: "Stillwater",
    Virginia: "Virginia",
    Willmar: "Willmar",
    Winona: "Winona",
    "Bay Saint Louis": "Bay Saint Louis",
    Biloxi: "Biloxi",
    Clarksdale: "Clarksdale",
    Corinth: "Corinth",
    Greenwood: "Greenwood",
    Grenada: "Grenada",
    Gulfport: "Gulfport",
    Hattiesburg: "Hattiesburg",
    "Holly Springs": "Holly Springs",
    Meridian: "Meridian",
    Natchez: "Natchez",
    "Ocean Springs": "Ocean Springs",
    Oxford: "Oxford",
    Pascagoula: "Pascagoula",
    "Pass Christian": "Pass Christian",
    Philadelphia: "Philadelphia",
    "Port Gibson": "Port Gibson",
    Starkville: "Starkville",
    Tupelo: "Tupelo",
    Vicksburg: "Vicksburg",
    "West Point": "West Point",
    "Yazoo City": "Yazoo City",
    Boonville: "Boonville",
    Branson: "Branson",
    "Cape Girardeau": "Cape Girardeau",
    Chillicothe: "Chillicothe",
    Clayton: "Clayton",
    "Excelsior Springs": "Excelsior Springs",
    Ferguson: "Ferguson",
    Florissant: "Florissant",
    Fulton: "Fulton",
    Hannibal: "Hannibal",
    "Jefferson City": "Jefferson City",
    Joplin: "Joplin",
    Kirksville: "Kirksville",
    Lamar: "Lamar",
    Maryville: "Maryville",
    Mexico: "Mexico",
    Monett: "Monett",
    Neosho: "Neosho",
    "New Madrid": "New Madrid",
    Rolla: "Rolla",
    "Saint Charles": "Saint Charles",
    "Saint Louis": "Saint Louis",
    "Sainte Genevieve": "Sainte Genevieve",
    Sedalia: "Sedalia",
    Warrensburg: "Warrensburg",
    "West Plains": "West Plains",
    Anaconda: "Anaconda",
    Billings: "Billings",
    Bozeman: "Bozeman",
    Butte: "Butte",
    Dillon: "Dillon",
    "Fort Benton": "Fort Benton",
    Glendive: "Glendive",
    "Great Falls": "Great Falls",
    Havre: "Havre",
    Kalispell: "Kalispell",
    Lewistown: "Lewistown",
    Livingston: "Livingston",
    "Miles City": "Miles City",
    Missoula: "Missoula",
    "Virginia City": "Virginia City",
    Beatrice: "Beatrice",
    Bellevue: "Bellevue",
    "Boys Town": "Boys Town",
    Chadron: "Chadron",
    McCook: "McCook",
    Minden: "Minden",
    "Nebraska City": "Nebraska City",
    Norfolk: "Norfolk",
    "North Platte": "North Platte",
    Omaha: "Omaha",
    Plattsmouth: "Plattsmouth",
    "Red Cloud": "Red Cloud",
    Sidney: "Sidney",
    "Boulder City": "Boulder City",
    "Carson City": "Carson City",
    Elko: "Elko",
    Fallon: "Fallon",
    Genoa: "Genoa",
    Goldfield: "Goldfield",
    "Las Vegas": "Las Vegas",
    "North Las Vegas": "North Las Vegas",
    Reno: "Reno",
    Sparks: "Sparks",
    Durham: "Durham",
    Exeter: "Exeter",
    Franklin: "Franklin",
    Hanover: "Hanover",
    Hillsborough: "Hillsborough",
    Keene: "Keene",
    Laconia: "Laconia",
    Somersworth: "Somersworth",
    "Asbury Park": "Asbury Park",
    "Atlantic City": "Atlantic City",
    Bayonne: "Bayonne",
    Bordentown: "Bordentown",
    "Bound Brook": "Bound Brook",
    Bridgeton: "Bridgeton",
    "Cape May": "Cape May",
    Cranford: "Cranford",
    "East Orange": "East Orange",
    Edison: "Edison",
    Elizabeth: "Elizabeth",
    "Fort Lee": "Fort Lee",
    Glassboro: "Glassboro",
    Hackensack: "Hackensack",
    Haddonfield: "Haddonfield",
    Hoboken: "Hoboken",
    Irvington: "Irvington",
    "Jersey City": "Jersey City",
    Lakehurst: "Lakehurst",
    Lakewood: "Lakewood",
    "Long Branch": "Long Branch",
    Millburn: "Millburn",
    Millville: "Millville",
    Montclair: "Montclair",
    Morristown: "Morristown",
    "Mount Holly": "Mount Holly",
    "New Brunswick": "New Brunswick",
    "New Milford": "New Milford",
    Passaic: "Passaic",
    Paterson: "Paterson",
    "Perth Amboy": "Perth Amboy",
    Plainfield: "Plainfield",
    Princeton: "Princeton",
    Ridgewood: "Ridgewood",
    Roselle: "Roselle",
    Rutherford: "Rutherford",
    "South Orange Village": "South Orange Village",
    Totowa: "Totowa",
    Union: "Union",
    "Union City": "Union City",
    Vineland: "Vineland",
    Wayne: "Wayne",
    Weehawken: "Weehawken",
    "West New York": "West New York",
    "West Orange": "West Orange",
    Willingboro: "Willingboro",
    Woodbridge: "Woodbridge",
    Acoma: "Acoma",
    Alamogordo: "Alamogordo",
    Albuquerque: "Albuquerque",
    Artesia: "Artesia",
    Belen: "Belen",
    Amsterdam: "Amsterdam",
    Babylon: "Babylon",
    Batavia: "Batavia",
    Beacon: "Beacon",
    Binghamton: "Binghamton",
    Bronx: "Bronx",
    Brooklyn: "Brooklyn",
    Buffalo: "Buffalo",
    Chautauqua: "Chautauqua",
    Cheektowaga: "Cheektowaga",
    Cohoes: "Cohoes",
    "Coney Island": "Coney Island",
    Cooperstown: "Cooperstown",
    Corning: "Corning",
    Cortland: "Cortland",
    "Crown Point": "Crown Point",
    Dunkirk: "Dunkirk",
    "East Aurora": "East Aurora",
    "East Hampton": "East Hampton",
    Eastchester: "Eastchester",
    Elmira: "Elmira",
    Flushing: "Flushing",
    "Forest Hills": "Forest Hills",
    Fredonia: "Fredonia",
    "Glens Falls": "Glens Falls",
    Gloversville: "Gloversville",
    "Great Neck": "Great Neck",
    Hammondsport: "Hammondsport",
    Harlem: "Harlem",
    Hempstead: "Hempstead",
    Herkimer: "Herkimer",
    Hudson: "Hudson",
    "Hyde Park": "Hyde Park",
    Ilion: "Ilion",
    Ithaca: "Ithaca",
    Jamestown: "Jamestown",
    Johnstown: "Johnstown",
    Kingston: "Kingston",
    Lackawanna: "Lackawanna",
    "Lake Placid": "Lake Placid",
    Levittown: "Levittown",
    Lockport: "Lockport",
    Mamaroneck: "Mamaroneck",
    Massena: "Massena",
    "New Paltz": "New Paltz",
    "New Rochelle": "New Rochelle",
    "New Windsor": "New Windsor",
    "New York City": "New York City",
    Newburgh: "Newburgh",
    "Niagara Falls": "Niagara Falls",
    "North Hempstead": "North Hempstead",
    Nyack: "Nyack",
    Ogdensburg: "Ogdensburg",
    Olean: "Olean",
    Oneida: "Oneida",
    Oneonta: "Oneonta",
    Ossining: "Ossining",
    Oswego: "Oswego",
    "Oyster Bay": "Oyster Bay",
    Palmyra: "Palmyra",
    Peekskill: "Peekskill",
    Plattsburgh: "Plattsburgh",
    "Port Washington": "Port Washington",
    Potsdam: "Potsdam",
    Poughkeepsie: "Poughkeepsie",
    Queens: "Queens",
    Rensselaer: "Rensselaer",
    Rotterdam: "Rotterdam",
    Rye: "Rye",
    "Sag Harbor": "Sag Harbor",
    "Saranac Lake": "Saranac Lake",
    "Saratoga Springs": "Saratoga Springs",
    Scarsdale: "Scarsdale",
    Schenectady: "Schenectady",
    "Seneca Falls": "Seneca Falls",
    Southampton: "Southampton",
    "Staten Island": "Staten Island",
    "Stony Brook": "Stony Brook",
    "Stony Point": "Stony Point",
    Syracuse: "Syracuse",
    Tarrytown: "Tarrytown",
    Ticonderoga: "Ticonderoga",
    Tonawanda: "Tonawanda",
    Utica: "Utica",
    Watervliet: "Watervliet",
    "Watkins Glen": "Watkins Glen",
    "West Seneca": "West Seneca",
    "White Plains": "White Plains",
    Woodstock: "Woodstock",
    Yonkers: "Yonkers",
    Clovis: "Clovis",
    Deming: "Deming",
    Gallup: "Gallup",
    Grants: "Grants",
    Hobbs: "Hobbs",
    "Las Cruces": "Las Cruces",
    "Los Alamos": "Los Alamos",
    Lovington: "Lovington",
    Portales: "Portales",
    Raton: "Raton",
    Roswell: "Roswell",
    "Santa Fe": "Santa Fe",
    Shiprock: "Shiprock",
    "Silver City": "Silver City",
    Socorro: "Socorro",
    Taos: "Taos",
    "Truth or Consequences": "Truth or Consequences",
    Tucumcari: "Tucumcari",
    Asheboro: "Asheboro",
    Asheville: "Asheville",
    "Chapel Hill": "Chapel Hill",
    Charlotte: "Charlotte",
    Edenton: "Edenton",
    "Elizabeth City": "Elizabeth City",
    Gastonia: "Gastonia",
    Goldsboro: "Goldsboro",
    Greensboro: "Greensboro",
    Halifax: "Halifax",
    Hickory: "Hickory",
    "High Point": "High Point",
    Kinston: "Kinston",
    "Kitty Hawk": "Kitty Hawk",
    Lumberton: "Lumberton",
    "Morehead City": "Morehead City",
    Morganton: "Morganton",
    "Nags Head": "Nags Head",
    "New Bern": "New Bern",
    Pinehurst: "Pinehurst",
    Raleigh: "Raleigh",
    "Rocky Mount": "Rocky Mount",
    Shelby: "Shelby",
    Wilson: "Wilson",
    "Winston-Salem": "Winston-Salem",
    Bismarck: "Bismarck",
    "Devils Lake": "Devils Lake",
    Dickinson: "Dickinson",
    Fargo: "Fargo",
    "Grand Forks": "Grand Forks",

    Mandan: "Mandan",
    Minot: "Minot",
    Rugby: "Rugby",
    "Valley City": "Valley City",
    Wahpeton: "Wahpeton",
    Williston: "Williston",
    Akron: "Akron",
    Alliance: "Alliance",
    Ashtabula: "Ashtabula",
    Barberton: "Barberton",
    Bellefontaine: "Bellefontaine",
    Cincinnati: "Cincinnati",
    Cleveland: "Cleveland",
    "Cleveland Heights": "Cleveland Heights",
    Conneaut: "Conneaut",
    "Cuyahoga Falls": "Cuyahoga Falls",
    Dayton: "Dayton",
    Defiance: "Defiance",
    Delaware: "Delaware",
    "East Cleveland": "East Cleveland",

    "East Liverpool": "East Liverpool",
    Elyria: "Elyria",
    Euclid: "Euclid",
    Findlay: "Findlay",
    Gallipolis: "Gallipolis",
    Hamilton: "Hamilton",
    Kent: "Kent",
    Kettering: "Kettering",
    Lima: "Lima",
    Lorain: "Lorain",
    "Martins Ferry": "Martins Ferry",
    Massillon: "Massillon",
    Mentor: "Mentor",
    Milan: "Milan",
    "New Philadelphia": "New Philadelphia",
    "North College Hill": "North College Hill",
    Oberlin: "Oberlin",
    Painesville: "Painesville",
    Parma: "Parma",
    Piqua: "Piqua",
    "Put-in-Bay": "Put-in-Bay",
    Sandusky: "Sandusky",
    "Shaker Heights": "Shaker Heights",
    Steubenville: "Steubenville",
    Tiffin: "Tiffin",
    Toledo: "Toledo",
    Wooster: "Wooster",
    Worthington: "Worthington",
    Xenia: "Xenia",
    "Yellow Springs": "Yellow Springs",
    Youngstown: "Youngstown",
    Zanesville: "Zanesville",
    Ada: "Ada",
    Altus: "Altus",
    Alva: "Alva",
    Anadarko: "Anadarko",
    Ardmore: "Ardmore",
    Bartlesville: "Bartlesville",
    Bethany: "Bethany",
    Chickasha: "Chickasha",
    Claremore: "Claremore",
    Cushing: "Cushing",
    Duncan: "Duncan",
    Durant: "Durant",
    Edmond: "Edmond",
    "El Reno": "El Reno",
    "Elk City": "Elk City",
    Enid: "Enid",
    Guthrie: "Guthrie",
    Guymon: "Guymon",
    Holdenville: "Holdenville",
    Hugo: "Hugo",
    Lawton: "Lawton",
    McAlester: "McAlester",
    "Midwest City": "Midwest City",
    Moore: "Moore",
    Muskogee: "Muskogee",
    Norman: "Norman",
    "Oklahoma City": "Oklahoma City",
    Okmulgee: "Okmulgee",
    "Pauls Valley": "Pauls Valley",
    Pawhuska: "Pawhuska",
    Perry: "Perry",
    "Ponca City": "Ponca City",
    Pryor: "Pryor",
    Sallisaw: "Sallisaw",
    "Sand Springs": "Sand Springs",
    Sapulpa: "Sapulpa",
    Seminole: "Seminole",
    Tahlequah: "Tahlequah",
    "The Village": "The Village",
    Tulsa: "Tulsa",
    Vinita: "Vinita",
    Wewoka: "Wewoka",
    Woodward: "Woodward",
    Astoria: "Astoria",
    "Baker City": "Baker City",
    Beaverton: "Beaverton",
    Bend: "Bend",
    Brookings: "Brookings",
    Burns: "Burns",
    "Coos Bay": "Coos Bay",
    Corvallis: "Corvallis",
    Eugene: "Eugene",
    "Grants Pass": "Grants Pass",
    Hillsboro: "Hillsboro",
    "Hood River": "Hood River",
    "John Day": "John Day",
    "Klamath Falls": "Klamath Falls",
    "La Grande": "La Grande",
    "Lake Oswego": "Lake Oswego",
    Lakeview: "Lakeview",
    McMinnville: "McMinnville",
    "Oregon City": "Oregon City",
    Pendleton: "Pendleton",
    "Port Orford": "Port Orford",
    Prineville: "Prineville",
    Redmond: "Redmond",
    Reedsport: "Reedsport",
    Roseburg: "Roseburg",
    Seaside: "Seaside",
    "The Dalles": "The Dalles",
    Tillamook: "Tillamook",
    Aliquippa: "Aliquippa",
    Allentown: "Allentown",
    Altoona: "Altoona",
    Ambridge: "Ambridge",
    Bethlehem: "Bethlehem",
    Bloomsburg: "Bloomsburg",
    Bradford: "Bradford",

    Honesdale: "Honesdale",
    Indiana: "Indiana",
    Jeannette: "Jeannette",
    "Jim Thorpe": "Jim Thorpe",
    "Lock Haven": "Lock Haven",
    "Lower Southampton": "Lower Southampton",
    McKeesport: "McKeesport",
    Meadville: "Meadville",
    Monroeville: "Monroeville",
    Nanticoke: "Nanticoke",
    "New Hope": "New Hope",
    "New Kensington": "New Kensington",
    Norristown: "Norristo",
    Phoenixville: "Phoenixville",
    Pittsburgh: "Pittsburgh",
    Pottstown: "Pottstown",
    Pottsville: "Pottsville",
    Reading: "Reading",
    Scranton: "Scranton",
    Shamokin: "Shamokin",
    Sharon: "Sharon",
    "State College": "State College",
    Stroudsburg: "Stroudsburg",
    Sunbury: "Sunbury",
    Swarthmore: "Swarthmore",
    Tamaqua: "Tamaqua",
    Uniontown: "Uniontown",
    "West Chester": "West Chester",
    "Wilkes-Barre": "Wilkes-Barre",
    Williamsport: "Williamsport",
    Barrington: "Barrington",
    "Central Falls": "Central Falls",
    Cranston: "Cranston",
    "East Greenwich": "East Greenwich",
    "East Providence": "East Providence",
    "North Kingstown": "North Kingstown",
    Pawtucket: "Pawtucket",
    Portsmouth: "Portsmouth",
    Providence: "Providence",
    "South Kingstown": "South Kingstown",
    Tiverton: "Tiverton",
    Warwick: "Warwick",
    Westerly: "Westerly",
    Wickford: "Wickford",
    Woonsocket: "Woonsocket",
    "Myrtle Beach": "Myrtle Beach",
    Orangeburg: "Orangeburg",
    "Rock Hill": "Rock Hill",
    Spartanburg: "Spartanburg",
    Sumter: "Sumter",
    "Belle Fourche": "Belle Fourche",
    Custer: "Custer",
    "De Smet": "De Smet",
    Deadwood: "Deadwood",
    Huron: "Huron",
    Lead: "Lead",
    Milbank: "Milbank",
    Mitchell: "Mitchell",
    Mobridge: "Mobridge",
    Pierre: "Pierre",
    "Rapid City": "Rapid City",
    "Sioux Falls": "Sioux Falls",
    Spearfish: "Spearfish",
    Sturgis: "Sturgis",
    Vermillion: "Vermillion",
    Yankton: "Yankton",
    Alcoa: "Alcoa",
    Chattanooga: "Chattanooga",
    Clarksville: "Clarksville",
    Cookeville: "Cookeville",
    Elizabethton: "Elizabethton",
    Gallatin: "Gallatin",
    Gatlinburg: "Gatlinburg",
    Greeneville: "Greeneville",
    "Johnson City": "Johnson City",
    Jonesborough: "Jonesborough",
    Kingsport: "Kingsport",
    Knoxville: "Knoxville",
    Memphis: "Memphis",
    Murfreesboro: "Murfreesboro",
    Norris: "Norris",
    "Oak Ridge": "Oak Ridge",
    Tullahoma: "Tullahoma",
    Alpine: "Alpine",
    Amarillo: "Amarillo",
    Baytown: "Baytown",
    Beaumont: "Beaumont",
    "Big Spring": "Big Spring",
    Borger: "Borger",
    Brownsville: "Brownsville",
    Bryan: "Bryan",
    Canyon: "Canyon",
    Cleburne: "Cleburne",
    "College Station": "College Station",
    "Corpus Christi": "Corpus Christi",
    "Crystal City": "Crystal City",
    Dallas: "Dallas",
    "Del Rio": "Del Rio",
    Denison: "Denison",
    Denton: "Denton",
    "Eagle Pass": "Eagle Pass",
    Edinburg: "Edinburg",
    "El Paso": "El Paso",
    "Fort Worth": "Fort Worth",
    Galveston: "Galveston",
    Garland: "Garland",
    Goliad: "Goliad",
    Harlingen: "Harlingen",
    Houston: "Houston",
    Irving: "Irving",
    Kilgore: "Kilgore",
    Killeen: "Killeen",
    Kingsville: "Kingsville",
    Laredo: "Laredo",
    Longview: "Longview",
    Lubbock: "Lubbock",
    Lufkin: "Lufkin",
    Marshall: "Marshall",
    McAllen: "McAllen",
    McKinney: "McKinney",
    Mesquite: "Mesquite",
    Mission: "Mission",
    Nacogdoches: "Nacogdoches",
    "New Braunfels": "New Braunfels",
    Odessa: "Odessa",
    Pampa: "Pampa",
    Pecos: "Pecos",
    Pharr: "Pharr",
    Plainview: "Plainview",
    Plano: "Plano",
    "Port Arthur": "Port Arthur",
    "Port Lavaca": "Port Lavaca",
    Richardson: "Richardson",
    "San Angelo": "San Angelo",
    "San Antonio": "San Antonio",
    "San Felipe": "San Felipe",
    "San Marcos": "San Marcos",
    Sherman: "Sherman",
    Sweetwater: "Sweetwater",
    Temple: "Temple",
    Texarkana: "Texarkana",
    "Texas City": "Texas City",
    Tyler: "Tyler",
    Uvalde: "Uvalde",
    Victoria: "Victoria",
    Waco: "Waco",
    Weatherford: "Weatherford",
    "Wichita Falls": "Wichita Falls",
    Ysleta: "Ysleta",
    Alta: "Alta",
    "American Fork": "American Fork",
    Bountiful: "Bountiful",
    "Brigham City": "Brigham City",
    "Cedar City": "Cedar City",
    Clearfield: "Clearfield",
    Delta: "Delta",
    Fillmore: "Fillmore",
    "Green River": "Green River",
    "Heber City": "Heber City",
    Kanab: "Kanab",
    Layton: "Layton",
    Lehi: "Lehi",
    Logan: "Logan",
    Manti: "Manti",
    Moab: "Moab",
    Monticello: "Monticello",
    Murray: "Murray",
    Nephi: "Nephi",
    Ogden: "Ogden",
    Orderville: "Orderville",
    Orem: "Orem",
    Panguitch: "Panguitch",
    "Park City": "Park City",
    Payson: "Payson",
    Price: "Price",
    Provo: "Provo",
    "Saint George": "Saint George",
    "Salt Lake City": "Salt Lake City",
    "Spanish Fork": "Spanish Fork",
    Springville: "Springville",
    Tooele: "Tooele",
    Vernal: "Vernal",
    Barre: "Barre",
    "Bellows Falls": "Bellows Falls",
    Bennington: "Bennington",
    Brattleboro: "Brattleboro",
    Essex: "Essex",
    Middlebury: "Middlebury",
    Montpelier: "Montpelier",
    Rutland: "Rutland",
    "Saint Albans": "Saint Albans",
    "Saint Johnsbury": "Saint Johnsbury",
    Winooski: "Winooski",
    Abingdon: "Abingdon",
    Charlottesville: "Charlottesville",
    Chesapeake: "Chesapeake",
    Fairfax: "Fairfax",
    "Falls Church": "Falls Church",
    Fredericksburg: "Fredericksburg",
    Hampton: "Hampton",
    Hopewell: "Hopewell",
    Lynchburg: "Lynchburg",
    Manassas: "Manassas",
    Martinsville: "Martinsville",
    "New Market": "New Market",
    "Newport News": "Newport News",
    Roanoke: "Roanoke",
    Staunton: "Staunton",
    Suffolk: "Suffolk",
    "Virginia Beach": "Virginia Beach",
    Waynesboro: "Waynesboro",
    Williamsburg: "Williamsburg",
    Winchester: "Winchester",
    Anacortes: "Anacortes",
    Bellingham: "Bellingham",
    Bremerton: "Bremerton",
    "Coulee Dam": "Coulee Dam",
    Coupeville: "Coupeville",
    Ellensburg: "Ellensburg",
    Ephrata: "Ephrata",
    Hoquiam: "Hoquiam",
    Kelso: "Kelso",
    Kennewick: "Kennewick",
    "Moses Lake": "Moses Lake",
    "Oak Harbor": "Oak Harbor",
    Olympia: "Olympia",
    Pasco: "Pasco",
    "Point Roberts": "Point Roberts",
    "Port Angeles": "Port Angeles",
    Pullman: "Pullman",
    Puyallup: "Puyallup",
    Renton: "Renton",
    Richland: "Richland",
    Seattle: "Seattle",
    Spokane: "Spokane",
    Tacoma: "Tacoma",
    Vancouver: "Vancouver",
    "Walla Walla": "Walla Walla",
    Wenatchee: "Wenatchee",
    Yakima: "Yakima",
    Beckley: "Beckley",
    Bluefield: "Bluefield",
    Buckhannon: "Buckhannon",
    "Charles Town": "Charles Town",
    Clarksburg: "Clarksburg",
    Elkins: "Elkins",
    Fairmont: "Fairmont",
    Grafton: "Grafton",
    "Harpers Ferry": "Harpers Ferry",
    Hinton: "Hinton",
    Keyser: "Keyser",
    Lewisburg: "Lewisburg",
    Martinsburg: "Martinsburg",
    Morgantown: "Morgantown",
    Moundsville: "Moundsville",
    "New Martinsville": "New Martinsville",
    Parkersburg: "Parkersburg",
    Philippi: "Philippi",
    "Point Pleasant": "Point Pleasant",
    Romney: "Romney",
    Shepherdstown: "Shepherdstown",
    "South Charleston": "South Charleston",
    Summersville: "Summersville",
    Weirton: "Weirton",
    Welch: "Welch",
    Wellsburg: "Wellsburg",
    Weston: "Weston",
    Wheeling: "Wheeling",
    "White Sulphur Springs": "White Sulphur Springs",
    Williamson: "Williamson",
    Appleton: "Appleton",
    Baraboo: "Baraboo",
    Beloit: "Beloit",
    "Eau Claire": "Eau Claire",
    "Fond du Lac": "Fond du Lac",
    "Green Bay": "Green Bay",
    Janesville: "Janesville",
    Kenosha: "Kenosha",
    "La Crosse": "La Crosse",
    "Lake Geneva": "Lake Geneva",
    Manitowoc: "Manitowoc",
    Marinette: "Marinette",
    Menasha: "Menasha",
    Milwaukee: "Milwaukee",
    Neenah: "Neenah",
    "New Glarus": "New Glarus",
    Oconto: "Oconto",
    Oshkosh: "Oshkosh",
    Peshtigo: "Peshtigo",
    Portage: "Portage",
    "Prairie du Chien": "Prairie du Chien",
    Racine: "Racine",
    Rhinelander: "Rhinelander",
    Ripon: "Ripon",
    Sheboygan: "Sheboygan",
    "Spring Green": "Spring Green",
    "Stevens Point": "Stevens Point",
    "Sturgeon Bay": "Sturgeon Bay",
    Superior: "Superior",
    Waukesha: "Waukesha",
    Wausau: "Wausau",
    Wauwatosa: "Wauwatosa",
    "West Allis": "West Allis",
    "West Bend": "West Bend",
    "Wisconsin Dells": "Wisconsin Dells",
    Casper: "Casper",
    Cheyenne: "Cheyenne",
    Cody: "Cody",
    Lander: "Lander",
    Laramie: "Laramie",
    Newcastle: "Newcastle",
    Powell: "Powell",
    Rawlins: "Rawlins",
    Riverton: "Riverton",
    "Rock Springs": "Rock Springs",
    Sheridan: "Sheridan",
    "Ten Sleep": "Ten Sleep",
    Thermopolis: "Thermopolis",
    Worland: "Worland",
};
